/*.Shots {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

}

.Shots-item {
  flex: 1;
  flex-basis: 25%;
}*/


.Shots {
  overflow-x: hidden;
}

.Shots-item {
  img {
    width: 100%;
  }
}
