/*------------------------------------
    #SPACING
------------------------------------*/

@media (min-width:$desk-start) {

  /**
   * Margin and padding helper classes. Use these to tweak layout on a micro
   * level.
   *
   * `.(m|p)(t|r|b|l|h|v)(-|+|0) {}` = margin/padding top/right/bottom/left/horizontal/vertical less/more/none
   */

  /* Predefine the variables below in order to alter and enable specific features. */
  $inuit-margin:                          24px; /* $base-spacing-unit !default; */
  $inuit-padding:                         24px; /* $base-spacing-unit !default; */



  /**
   * Margin helper classes.
   *
   * Add margins.
   */

  .u-lg-m       { margin:           $inuit-margin !important; }
  .u-lg-mt      { margin-top:       $inuit-margin !important; }
  .u-lg-mr      { margin-right:     $inuit-margin !important; }
  .u-lg-mb      { margin-bottom:    $inuit-margin !important; }
  .u-lg-ml      { margin-left:      $inuit-margin !important; }
  .u-lg-mh      { margin-right:     $inuit-margin !important; margin-left:      $inuit-margin !important; }
  .u-lg-mv      { margin-top:       $inuit-margin !important; margin-bottom:    $inuit-margin !important; }


  /**
   * Add tiny margins.
   */

  .u-lg-m--     { margin:           calc($inuit-margin / 4) !important; }
  .u-lg-mt--    { margin-top:       calc($inuit-margin / 4) !important; }
  .u-lg-mr--    { margin-right:     calc($inuit-margin / 4) !important; }
  .u-lg-mb--    { margin-bottom:    calc($inuit-margin / 4) !important; }
  .u-lg-ml--    { margin-left:      calc($inuit-margin / 4) !important; }
  .u-lg-mh--    { margin-right:     calc($inuit-margin / 4) !important; margin-left:     calc($inuit-margin / 4) !important; }
  .u-lg-mv--    { margin-top:       calc($inuit-margin / 4) !important; margin-bottom:   calc($inuit-margin / 4) !important; }


  /**
   * Add small margins.
   */

  .u-lg-m-      { margin:           calc($inuit-margin / 2) !important; }
  .u-lg-mt-     { margin-top:       calc($inuit-margin / 2) !important; }
  .u-lg-mr-     { margin-right:     calc($inuit-margin / 2) !important; }
  .u-lg-mb-     { margin-bottom:    calc($inuit-margin / 2) !important; }
  .u-lg-ml-     { margin-left:      calc($inuit-margin / 2) !important; }
  .u-lg-mh-     { margin-right:     calc($inuit-margin / 2) !important; margin-left:   calc($inuit-margin / 2) !important; }
  .u-lg-mv-     { margin-top:       calc($inuit-margin / 2) !important; margin-bottom: calc($inuit-margin / 2) !important; }


  /**
   * Add large margins.
   */

  .u-lg-m\+     { margin:           calc($inuit-margin * 2) !important; }
  .u-lg-mt\+    { margin-top:       calc($inuit-margin * 2) !important; }
  .u-lg-mr\+    { margin-right:     calc($inuit-margin * 2) !important; }
  .u-lg-mb\+    { margin-bottom:    calc($inuit-margin * 2) !important; }
  .u-lg-ml\+    { margin-left:      calc($inuit-margin * 2) !important; }
  .u-lg-mh\+    { margin-right:     calc($inuit-margin * 2) !important; margin-left:      calc($inuit-margin * 2) !important; }
  .u-lg-mv\+    { margin-top:       calc($inuit-margin * 2) !important; margin-bottom:    calc($inuit-margin * 2) !important; }


  /**
   * Add huge margins.
   */

  .u-lg-m\+\+   { margin:           calc($inuit-margin * 4) !important; }
  .u-lg-mt\+\+  { margin-top:       calc($inuit-margin * 4) !important; }
  .u-lg-mr\+\+  { margin-right:     calc($inuit-margin * 4) !important; }
  .u-lg-mb\+\+  { margin-bottom:    calc($inuit-margin * 4) !important; }
  .u-lg-ml\+\+  { margin-left:      calc($inuit-margin * 4) !important; }
  .u-lg-mh\+\+  { margin-right:     calc($inuit-margin * 4) !important; margin-left:   calc($inuit-margin * 4) !important; }
  .u-lg-mv\+\+  { margin-top:       calc($inuit-margin * 4) !important; margin-bottom: calc($inuit-margin * 4) !important; }


  /**
   * Remove margins.
   */

  .u-lg-m0      { margin:           0 !important; }
  .u-lg-mt0     { margin-top:       0 !important; }
  .u-lg-mr0     { margin-right:     0 !important; }
  .u-lg-mb0     { margin-bottom:    0 !important; }
  .u-lg-ml0     { margin-left:      0 !important; }
  .u-lg-mh0     { margin-right:     0 !important; margin-left:      0 !important; }
  .u-lg-mv0     { margin-top:       0 !important; margin-bottom:    0 !important; }


  /**
   * Negative margins.
   */

  .u-lg--m      { margin:           -$inuit-margin !important; }
  .u-lg--mt     { margin-top:       -$inuit-margin !important; }
  .u-lg--mr     { margin-right:     -$inuit-margin !important; }
  .u-lg--mb     { margin-bottom:    -$inuit-margin !important; }
  .u-lg--ml     { margin-left:      -$inuit-margin !important; }
  .u-lg--mh     { margin-right:     -$inuit-margin !important; margin-left:      -$inuit-margin !important; }
  .u-lg--mv     { margin-top:       -$inuit-margin !important; margin-bottom:    -$inuit-margin !important; }


  /**
   * Tiny negative margins.
   */

  .u-lg--m--    { margin:           calc(-$inuit-margin / 4) !important; }
  .u-lg--mt--   { margin-top:       calc(-$inuit-margin / 4) !important; }
  .u-lg--mr--   { margin-right:     calc(-$inuit-margin / 4) !important; }
  .u-lg--mb--   { margin-bottom:    calc(-$inuit-margin / 4) !important; }
  .u-lg--ml--   { margin-left:      calc(-$inuit-margin / 4) !important; }
  .u-lg--mh--   { margin-right:     calc(-$inuit-margin / 4) !important; margin-left:     calc(-$inuit-margin / 4) !important; }
  .u-lg--mv--   { margin-top:       calc(-$inuit-margin / 4) !important; margin-bottom:   calc(-$inuit-margin / 4) !important; }


  /**
   * Small negative margins.
   */

  .u-lg--m-     { margin:           calc(-$inuit-margin / 2) !important; }
  .u-lg--mt-    { margin-top:       calc(-$inuit-margin / 2) !important; }
  .u-lg--mr-    { margin-right:     calc(-$inuit-margin / 2) !important; }
  .u-lg--mb-    { margin-bottom:    calc(-$inuit-margin / 2) !important; }
  .u-lg--ml-    { margin-left:      calc(-$inuit-margin / 2) !important; }
  .u-lg--mh-    { margin-right:     calc(-$inuit-margin / 2) !important; margin-left:   calc(-$inuit-margin / 2) !important; }
  .u-lg--mv-    { margin-top:       calc(-$inuit-margin / 2) !important; margin-bottom: calc(-$inuit-margin / 2) !important; }


  /**
   * Large negative margins.
   */

  .u-lg--m\+    { margin:           calc(-$inuit-margin * 2) !important; }
  .u-lg--mt\+   { margin-top:       calc(-$inuit-margin * 2) !important; }
  .u-lg--mr\+   { margin-right:     calc(-$inuit-margin * 2) !important; }
  .u-lg--mb\+   { margin-bottom:    calc(-$inuit-margin * 2) !important; }
  .u-lg--ml\+   { margin-left:      calc(-$inuit-margin * 2) !important; }
  .u-lg--mh\+   { margin-right:     calc(-$inuit-margin * 2) !important; margin-left:      calc(-$inuit-margin * 2) !important; }
  .u-lg--mv\+   { margin-top:       calc(-$inuit-margin * 2) !important; margin-bottom:    calc(-$inuit-margin * 2) !important; }


  /**
   * Huge negative margins.
   */

  .u-lg--m\+\+  { margin:           calc(-$inuit-margin * 4) !important; }
  .u-lg--mt\+\+ { margin-top:       calc(-$inuit-margin * 4) !important; }
  .u-lg--mr\+\+ { margin-right:     calc(-$inuit-margin * 4) !important; }
  .u-lg--mb\+\+ { margin-bottom:    calc(-$inuit-margin * 4) !important; }
  .u-lg--ml\+\+ { margin-left:      calc(-$inuit-margin * 4) !important; }
  .u-lg--mh\+\+ { margin-right:     calc(-$inuit-margin * 4) !important; margin-left:   calc(-$inuit-margin * 4) !important; }
  .u-lg--mv\+\+ { margin-top:       calc(-$inuit-margin * 4) !important; margin-bottom: calc(-$inuit-margin * 4) !important; }


  /**
   * Padding helper classes.
   *
   * Add paddings.
   */

  .u-lg-p       { padding:          $inuit-padding !important; }
  .u-lg-pt      { padding-top:      $inuit-padding !important; }
  .u-lg-pr      { padding-right:    $inuit-padding !important; }
  .u-lg-pb      { padding-bottom:   $inuit-padding !important; }
  .u-lg-pl      { padding-left:     $inuit-padding !important; }
  .u-lg-ph      { padding-right:    $inuit-padding !important; padding-left:    $inuit-padding !important; }
  .u-lg-pv      { padding-top:      $inuit-padding !important; padding-bottom:  $inuit-padding !important; }


  /**
   * Add tiny paddings.
   */

  .u-lg-p--     { padding:           calc($inuit-padding / 4) !important; }
  .u-lg-pt--    { padding-top:       calc($inuit-padding / 4) !important; }
  .u-lg-pr--    { padding-right:     calc($inuit-padding / 4) !important; }
  .u-lg-pb--    { padding-bottom:    calc($inuit-padding / 4) !important; }
  .u-lg-pl--    { padding-left:      calc($inuit-padding / 4) !important; }
  .u-lg-ph--    { padding-right:     calc($inuit-padding / 4) !important; padding-left:      calc($inuit-padding / 4) !important; }
  .u-lg-pv--    { padding-top:       calc($inuit-padding / 4) !important; padding-bottom:    calc($inuit-padding / 4) !important; }


  /**
   * Add small paddings.
   */

  .u-lg-p-      { padding:           calc($inuit-padding / 2) !important; }
  .u-lg-pt-     { padding-top:       calc($inuit-padding / 2) !important; }
  .u-lg-pr-     { padding-right:     calc($inuit-padding / 2) !important; }
  .u-lg-pb-     { padding-bottom:    calc($inuit-padding / 2) !important; }
  .u-lg-pl-     { padding-left:      calc($inuit-padding / 2) !important; }
  .u-lg-ph-     { padding-right:     calc($inuit-padding / 2) !important; padding-left:    calc($inuit-padding / 2) !important; }
  .u-lg-pv-     { padding-top:       calc($inuit-padding / 2) !important; padding-bottom:  calc($inuit-padding / 2) !important; }


  /**
   * Add large paddings.
   */

  .u-lg-p\+     { padding:           calc($inuit-padding * 2) !important; }
  .u-lg-pt\+    { padding-top:       calc($inuit-padding * 2) !important; }
  .u-lg-pr\+    { padding-right:     calc($inuit-padding * 2) !important; }
  .u-lg-pb\+    { padding-bottom:    calc($inuit-padding * 2) !important; }
  .u-lg-pl\+    { padding-left:      calc($inuit-padding * 2) !important; }
  .u-lg-ph\+    { padding-right:     calc($inuit-padding * 2) !important; padding-left:   calc($inuit-padding * 2) !important; }
  .u-lg-pv\+    { padding-top:       calc($inuit-padding * 2) !important; padding-bottom: calc($inuit-padding * 2) !important; }


  /**
   * Add huge paddings.
   */

  .u-lg-p\+\+   { padding:           calc($inuit-padding * 4) !important; }
  .u-lg-pt\+\+  { padding-top:       calc($inuit-padding * 4) !important; }
  .u-lg-pr\+\+  { padding-right:     calc($inuit-padding * 4) !important; }
  .u-lg-pb\+\+  { padding-bottom:    calc($inuit-padding * 4) !important; }
  .u-lg-pl\+\+  { padding-left:      calc($inuit-padding * 4) !important; }
  .u-lg-ph\+\+  { padding-right:     calc($inuit-padding * 4) !important; padding-left:    calc($inuit-padding * 4) !important; }
  .u-lg-pv\+\+  { padding-top:       calc($inuit-padding * 4) !important; padding-bottom:  calc($inuit-padding * 4) !important; }


  /**
   * Remove paddings.
   */

  .u-lg-p0      { padding:          0 !important; }
  .u-lg-pt0     { padding-top:      0 !important; }
  .u-lg-pr0     { padding-right:    0 !important; }
  .u-lg-pb0     { padding-bottom:   0 !important; }
  .u-lg-pl0     { padding-left:     0 !important; }
  .u-lg-ph0     { padding-right:    0 !important; padding-left:     0 !important; }
  .u-lg-pv0     { padding-top:      0 !important; padding-bottom:   0 !important; }

}
