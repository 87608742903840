/**
 * Hide content off-screen without resorting to `display:none;`, also provide
 * breakpoint specific hidden elements.
 */

@define-mixin HideVisually {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

@each $state in palm, lap, lap-and-up, portable, desk{
  @media (--$(state)){
    .u-isHiddenVisually--$(state){
      @mixin HideVisually;
    }
  }
}
