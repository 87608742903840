/**
 * Offset: breakpoint 3 (large)
 *
 * Specify the proportional offset before an element.
 * Intentional redundancy build into each set of unit classes.
 * Supports: 2, 3, 4, 5, 6, 8, 10, 12 section
 */

@media (min-width:$desk-start) {

  .u-lg-beforeNone {
    margin-left: 0 !important;
  }

  .u-lg-before1of12 {
    margin-left: calc(100% * 1 / 12) !important;
  }

  .u-lg-before1of10 {
    margin-left: 10% !important;
  }

  .u-lg-before1of8 {
    margin-left: 12.5% !important;
  }

  .u-lg-before1of6,
  .u-lg-before2of12 {
    margin-left: calc(100% * 1 / 6) !important;
  }

  .u-lg-before1of5,
  .u-lg-before2of10 {
    margin-left: 20% !important;
  }

  .u-lg-before1of4,
  .u-lg-before2of8,
  .u-lg-before3of12 {
    margin-left: 25% !important;
  }

  .u-lg-before3of10 {
    margin-left: 30% !important;
  }

  .u-lg-before1of3,
  .u-lg-before2of6,
  .u-lg-before4of12 {
    margin-left: calc(100% * 1 / 3) !important;
  }

  .u-lg-before3of8 {
    margin-left: 37.5% !important;
  }

  .u-lg-before2of5,
  .u-lg-before4of10 {
    margin-left: 40% !important;
  }

  .u-lg-before5of12 {
    margin-left: calc(100% * 5 / 12) !important;
  }

  .u-lg-before1of2,
  .u-lg-before2of4,
  .u-lg-before3of6,
  .u-lg-before4of8,
  .u-lg-before5of10,
  .u-lg-before6of12 {
    margin-left: 50% !important;
  }

  .u-lg-before7of12 {
    margin-left: calc(100% * 7 / 12) !important;
  }

  .u-lg-before3of5,
  .u-lg-before6of10 {
    margin-left: 60% !important;
  }

  .u-lg-before5of8 {
    margin-left: 62.5% !important;
  }

  .u-lg-before2of3,
  .u-lg-before4of6,
  .u-lg-before8of12 {
    margin-left: calc(100% * 2 / 3) !important;
  }

  .u-lg-before7of10 {
    margin-left: 70% !important;
  }

  .u-lg-before3of4,
  .u-lg-before6of8,
  .u-lg-before9of12 {
    margin-left: 75% !important;
  }

  .u-lg-before4of5,
  .u-lg-before8of10 {
    margin-left: 80% !important;
  }

  .u-lg-before5of6,
  .u-lg-before10of12 {
    margin-left: calc(100% * 5 / 6) !important;
  }

  .u-lg-before7of8 {
    margin-left: 87.5% !important;
  }

  .u-lg-before9of10 {
    margin-left: 90% !important;
  }

  .u-lg-before11of12 {
    margin-left: calc(100% * 11 / 12) !important;
  }

}
