.Header {
	/*min-height: 90px;*/
	display: flex;
	align-items: center;
  justify-content: space-between;

  color: #343232;
  background-size: contain;

  h1 {
    display: inline-block;
  }

	@media (--palm) {
		justify-content: center;
	}

	@media (--lap-and-up) {
		height: 112px;
	}
}

.Header-logo {
	line-height: 0;

	@media (--palm) {
		flex: 1;
	}
}

.HeaderNavigation {
	@media (--palm) {
		ul {
			display: flex;
			flex-wrap: wrap;

			text-align: right;
		}

		li {
			flex-basis: 100%;
		}

		a {
			margin: 0;
			padding: 0;

			text-decoration: underline;
		}
	}
}
