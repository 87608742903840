.Flex {
	display: flex;
}


.Flex--1 {
	flex: 1;
}

.Flex--2 {
	flex: 2;
}


.FlexRow {
	flex-direction: row;
}

.FlexColumn {
	flex-direction: column;
}



.FlexAI--center {
	align-items: center;
}

.FlexAI--flexStart {
	align-items: flex-start;
}

.FlexAI--flexEnd {
	align-items: flex-end;
}

.FlexAI--baseline {
	align-items: baseline;
}

.FlexAI--stretch {
	align-items: stretch;
}


.FlexJC--center {
	justify-content: center;
}

.FlexJC--flexStart {
	justify-content: flex-start;
}

.FlexJC--flexEnd {
	justify-content: flex-end;
}

.FlexJC--spaceBetween {
	justify-content: space-between;
}

.FlexJC--spaceAround {
	justify-content: space-around;
}