/*------------------------------------
    #SPACING
------------------------------------*/

@media (min-width:$desk-wide-start) {

  /**
   * Margin and padding helper classes. Use these to tweak layout on a micro
   * level.
   *
   * `.(m|p)(t|r|b|l|h|v)(-|+|0) {}` = margin/padding top/right/bottom/left/horizontal/vertical less/more/none
   */

  /* Predefine the variables below in order to alter and enable specific features. */
  $inuit-margin:                          24px; /* $base-spacing-unit !default; */
  $inuit-padding:                         24px; /* $base-spacing-unit !default; */



  /**
   * Margin helper classes.
   *
   * Add margins.
   */

  .u-xlg-m       { margin:           $inuit-margin !important; }
  .u-xlg-mt      { margin-top:       $inuit-margin !important; }
  .u-xlg-mr      { margin-right:     $inuit-margin !important; }
  .u-xlg-mb      { margin-bottom:    $inuit-margin !important; }
  .u-xlg-ml      { margin-left:      $inuit-margin !important; }
  .u-xlg-mh      { margin-right:     $inuit-margin !important; margin-left:      $inuit-margin !important; }
  .u-xlg-mv      { margin-top:       $inuit-margin !important; margin-bottom:    $inuit-margin !important; }


  /**
   * Add tiny margins.
   */

  .u-xlg-m--     { margin:           calc($inuit-margin / 4) !important; }
  .u-xlg-mt--    { margin-top:       calc($inuit-margin / 4) !important; }
  .u-xlg-mr--    { margin-right:     calc($inuit-margin / 4) !important; }
  .u-xlg-mb--    { margin-bottom:    calc($inuit-margin / 4) !important; }
  .u-xlg-ml--    { margin-left:      calc($inuit-margin / 4) !important; }
  .u-xlg-mh--    { margin-right:     calc($inuit-margin / 4) !important; margin-left:     calc($inuit-margin / 4) !important; }
  .u-xlg-mv--    { margin-top:       calc($inuit-margin / 4) !important; margin-bottom:   calc($inuit-margin / 4) !important; }


  /**
   * Add small margins.
   */

  .u-xlg-m-      { margin:           calc($inuit-margin / 2) !important; }
  .u-xlg-mt-     { margin-top:       calc($inuit-margin / 2) !important; }
  .u-xlg-mr-     { margin-right:     calc($inuit-margin / 2) !important; }
  .u-xlg-mb-     { margin-bottom:    calc($inuit-margin / 2) !important; }
  .u-xlg-ml-     { margin-left:      calc($inuit-margin / 2) !important; }
  .u-xlg-mh-     { margin-right:     calc($inuit-margin / 2) !important; margin-left:   calc($inuit-margin / 2) !important; }
  .u-xlg-mv-     { margin-top:       calc($inuit-margin / 2) !important; margin-bottom: calc($inuit-margin / 2) !important; }


  /**
   * Add large margins.
   */

  .u-xlg-m\+     { margin:           calc($inuit-margin * 2) !important; }
  .u-xlg-mt\+    { margin-top:       calc($inuit-margin * 2) !important; }
  .u-xlg-mr\+    { margin-right:     calc($inuit-margin * 2) !important; }
  .u-xlg-mb\+    { margin-bottom:    calc($inuit-margin * 2) !important; }
  .u-xlg-ml\+    { margin-left:      calc($inuit-margin * 2) !important; }
  .u-xlg-mh\+    { margin-right:     calc($inuit-margin * 2) !important; margin-left:      calc($inuit-margin * 2) !important; }
  .u-xlg-mv\+    { margin-top:       calc($inuit-margin * 2) !important; margin-bottom:    calc($inuit-margin * 2) !important; }


  /**
   * Add huge margins.
   */

  .u-xlg-m\+\+   { margin:           calc($inuit-margin * 4) !important; }
  .u-xlg-mt\+\+  { margin-top:       calc($inuit-margin * 4) !important; }
  .u-xlg-mr\+\+  { margin-right:     calc($inuit-margin * 4) !important; }
  .u-xlg-mb\+\+  { margin-bottom:    calc($inuit-margin * 4) !important; }
  .u-xlg-ml\+\+  { margin-left:      calc($inuit-margin * 4) !important; }
  .u-xlg-mh\+\+  { margin-right:     calc($inuit-margin * 4) !important; margin-left:   calc($inuit-margin * 4) !important; }
  .u-xlg-mv\+\+  { margin-top:       calc($inuit-margin * 4) !important; margin-bottom: calc($inuit-margin * 4) !important; }


  /**
   * Remove margins.
   */

  .u-xlg-m0      { margin:           0 !important; }
  .u-xlg-mt0     { margin-top:       0 !important; }
  .u-xlg-mr0     { margin-right:     0 !important; }
  .u-xlg-mb0     { margin-bottom:    0 !important; }
  .u-xlg-ml0     { margin-left:      0 !important; }
  .u-xlg-mh0     { margin-right:     0 !important; margin-left:      0 !important; }
  .u-xlg-mv0     { margin-top:       0 !important; margin-bottom:    0 !important; }


  /**
   * Negative margins.
   */

  .u-xlg--m      { margin:           -$inuit-margin !important; }
  .u-xlg--mt     { margin-top:       -$inuit-margin !important; }
  .u-xlg--mr     { margin-right:     -$inuit-margin !important; }
  .u-xlg--mb     { margin-bottom:    -$inuit-margin !important; }
  .u-xlg--ml     { margin-left:      -$inuit-margin !important; }
  .u-xlg--mh     { margin-right:     -$inuit-margin !important; margin-left:      -$inuit-margin !important; }
  .u-xlg--mv     { margin-top:       -$inuit-margin !important; margin-bottom:    -$inuit-margin !important; }


  /**
   * Tiny negative margins.
   */

  .u-xlg--m--    { margin:           calc(-$inuit-margin / 4) !important; }
  .u-xlg--mt--   { margin-top:       calc(-$inuit-margin / 4) !important; }
  .u-xlg--mr--   { margin-right:     calc(-$inuit-margin / 4) !important; }
  .u-xlg--mb--   { margin-bottom:    calc(-$inuit-margin / 4) !important; }
  .u-xlg--ml--   { margin-left:      calc(-$inuit-margin / 4) !important; }
  .u-xlg--mh--   { margin-right:     calc(-$inuit-margin / 4) !important; margin-left:     calc(-$inuit-margin / 4) !important; }
  .u-xlg--mv--   { margin-top:       calc(-$inuit-margin / 4) !important; margin-bottom:   calc(-$inuit-margin / 4) !important; }


  /**
   * Small negative margins.
   */

  .u-xlg--m-     { margin:           calc(-$inuit-margin / 2) !important; }
  .u-xlg--mt-    { margin-top:       calc(-$inuit-margin / 2) !important; }
  .u-xlg--mr-    { margin-right:     calc(-$inuit-margin / 2) !important; }
  .u-xlg--mb-    { margin-bottom:    calc(-$inuit-margin / 2) !important; }
  .u-xlg--ml-    { margin-left:      calc(-$inuit-margin / 2) !important; }
  .u-xlg--mh-    { margin-right:     calc(-$inuit-margin / 2) !important; margin-left:   calc(-$inuit-margin / 2) !important; }
  .u-xlg--mv-    { margin-top:       calc(-$inuit-margin / 2) !important; margin-bottom: calc(-$inuit-margin / 2) !important; }


  /**
   * Large negative margins.
   */

  .u-xlg--m\+    { margin:           calc(-$inuit-margin * 2) !important; }
  .u-xlg--mt\+   { margin-top:       calc(-$inuit-margin * 2) !important; }
  .u-xlg--mr\+   { margin-right:     calc(-$inuit-margin * 2) !important; }
  .u-xlg--mb\+   { margin-bottom:    calc(-$inuit-margin * 2) !important; }
  .u-xlg--ml\+   { margin-left:      calc(-$inuit-margin * 2) !important; }
  .u-xlg--mh\+   { margin-right:     calc(-$inuit-margin * 2) !important; margin-left:      calc(-$inuit-margin * 2) !important; }
  .u-xlg--mv\+   { margin-top:       calc(-$inuit-margin * 2) !important; margin-bottom:    calc(-$inuit-margin * 2) !important; }


  /**
   * Huge negative margins.
   */

  .u-xlg--m\+\+  { margin:           calc(-$inuit-margin * 4) !important; }
  .u-xlg--mt\+\+ { margin-top:       calc(-$inuit-margin * 4) !important; }
  .u-xlg--mr\+\+ { margin-right:     calc(-$inuit-margin * 4) !important; }
  .u-xlg--mb\+\+ { margin-bottom:    calc(-$inuit-margin * 4) !important; }
  .u-xlg--ml\+\+ { margin-left:      calc(-$inuit-margin * 4) !important; }
  .u-xlg--mh\+\+ { margin-right:     calc(-$inuit-margin * 4) !important; margin-left:   calc(-$inuit-margin * 4) !important; }
  .u-xlg--mv\+\+ { margin-top:       calc(-$inuit-margin * 4) !important; margin-bottom: calc(-$inuit-margin * 4) !important; }


  /**
   * Padding helper classes.
   *
   * Add paddings.
   */

  .u-xlg-p       { padding:          $inuit-padding !important; }
  .u-xlg-pt      { padding-top:      $inuit-padding !important; }
  .u-xlg-pr      { padding-right:    $inuit-padding !important; }
  .u-xlg-pb      { padding-bottom:   $inuit-padding !important; }
  .u-xlg-pl      { padding-left:     $inuit-padding !important; }
  .u-xlg-ph      { padding-right:    $inuit-padding !important; padding-left:    $inuit-padding !important; }
  .u-xlg-pv      { padding-top:      $inuit-padding !important; padding-bottom:  $inuit-padding !important; }


  /**
   * Add tiny paddings.
   */

  .u-xlg-p--     { padding:           calc($inuit-padding / 4) !important; }
  .u-xlg-pt--    { padding-top:       calc($inuit-padding / 4) !important; }
  .u-xlg-pr--    { padding-right:     calc($inuit-padding / 4) !important; }
  .u-xlg-pb--    { padding-bottom:    calc($inuit-padding / 4) !important; }
  .u-xlg-pl--    { padding-left:      calc($inuit-padding / 4) !important; }
  .u-xlg-ph--    { padding-right:     calc($inuit-padding / 4) !important; padding-left:      calc($inuit-padding / 4) !important; }
  .u-xlg-pv--    { padding-top:       calc($inuit-padding / 4) !important; padding-bottom:    calc($inuit-padding / 4) !important; }


  /**
   * Add small paddings.
   */

  .u-xlg-p-      { padding:           calc($inuit-padding / 2) !important; }
  .u-xlg-pt-     { padding-top:       calc($inuit-padding / 2) !important; }
  .u-xlg-pr-     { padding-right:     calc($inuit-padding / 2) !important; }
  .u-xlg-pb-     { padding-bottom:    calc($inuit-padding / 2) !important; }
  .u-xlg-pl-     { padding-left:      calc($inuit-padding / 2) !important; }
  .u-xlg-ph-     { padding-right:     calc($inuit-padding / 2) !important; padding-left:    calc($inuit-padding / 2) !important; }
  .u-xlg-pv-     { padding-top:       calc($inuit-padding / 2) !important; padding-bottom:  calc($inuit-padding / 2) !important; }


  /**
   * Add large paddings.
   */

  .u-xlg-p\+     { padding:           calc($inuit-padding * 2) !important; }
  .u-xlg-pt\+    { padding-top:       calc($inuit-padding * 2) !important; }
  .u-xlg-pr\+    { padding-right:     calc($inuit-padding * 2) !important; }
  .u-xlg-pb\+    { padding-bottom:    calc($inuit-padding * 2) !important; }
  .u-xlg-pl\+    { padding-left:      calc($inuit-padding * 2) !important; }
  .u-xlg-ph\+    { padding-right:     calc($inuit-padding * 2) !important; padding-left:   calc($inuit-padding * 2) !important; }
  .u-xlg-pv\+    { padding-top:       calc($inuit-padding * 2) !important; padding-bottom: calc($inuit-padding * 2) !important; }


  /**
   * Add huge paddings.
   */

  .u-xlg-p\+\+   { padding:           calc($inuit-padding * 4) !important; }
  .u-xlg-pt\+\+  { padding-top:       calc($inuit-padding * 4) !important; }
  .u-xlg-pr\+\+  { padding-right:     calc($inuit-padding * 4) !important; }
  .u-xlg-pb\+\+  { padding-bottom:    calc($inuit-padding * 4) !important; }
  .u-xlg-pl\+\+  { padding-left:      calc($inuit-padding * 4) !important; }
  .u-xlg-ph\+\+  { padding-right:     calc($inuit-padding * 4) !important; padding-left:    calc($inuit-padding * 4) !important; }
  .u-xlg-pv\+\+  { padding-top:       calc($inuit-padding * 4) !important; padding-bottom:  calc($inuit-padding * 4) !important; }


  /**
   * Remove paddings.
   */

  .u-xlg-p0      { padding:          0 !important; }
  .u-xlg-pt0     { padding-top:      0 !important; }
  .u-xlg-pr0     { padding-right:    0 !important; }
  .u-xlg-pb0     { padding-bottom:   0 !important; }
  .u-xlg-pl0     { padding-left:     0 !important; }
  .u-xlg-ph0     { padding-right:    0 !important; padding-left:     0 !important; }
  .u-xlg-pv0     { padding-top:      0 !important; padding-bottom:   0 !important; }

}
