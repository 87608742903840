.Projects {
  h1 {
		font-size: 56px;
		line-height: 58px;
		font-weight: $font-bl;
    color: #fff;

    @media (--palm) {
      font-size: 44px;
			line-height: 48px;
		}
	}

	h2 {
		font-size: 36px;
		line-height: 44px;
		font-weight: $font-re;

    @media (--palm) {
      font-size: 28px;
			line-height: 32px;
		}
	}

	p {
		font-size: 20px;
		line-height: 28px;
		font-weight: $font-lt;

    @media (--palm) {
			line-height: 26px;
		}
	}

  ul {
    font-size: 20px;
  }

  span {
    display: inline-block;
    font-size: 12px;
    line-height: 18px;
  }
}

.ProjectsHeader {
  width: 100%;
  height: 720px;
  background-size: cover;
  background-repeat: no-repeat;

  @media (--palm) {
    height: 400px;
  }
}

.ProjectsHeader--estimote {
  background: linear-gradient(
                rgba(99,0,74,0.9),
                rgba(99,0,74,0.9)
              ),
              url(../images/bg-estimote.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.ProjectsHeader--clockwork {
  background: linear-gradient(
                rgba(255,114,0,0.9),
                rgba(255,114,0,0.9)
              ),
              url(../images/bg-clockwork.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.ProjectsHeader--hacktory {
  background: linear-gradient(
                rgba(97,184,98,0.9),
                rgba(97,184,98,0.9)
              ),
              url(../images/bg-hacktory.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.ProjectsHeader--hautec {
  background: linear-gradient(
                rgba(34,56,125,0.9),
                rgba(34,56,125,0.9)
              ),
              url(../images/bg-hautec.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.ProjectsExit {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 48px;
  left: 48px;

  span {
    display: block;
    height: 24px;
    width: 24px;
    background-image: url(../images/exit-icon.png);
  }

  &:active,
  &:hover {
    transform: scale(1.2);
    text-decoration: none;
  }

  @media (--palm) {
    top: 24px;
    left: 24px;
  }
}

.ProjectsHeader-content {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.ProjectsContent-separator {
  border-bottom: 1px solid #D8D8D8;
}

.ProjectImage {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ProjectImage--center {
  justify-content: center;
}


.ProjectsCredits {
  span {
		font-size: 20px;
		line-height: 28px;
		font-weight: $font-lt;
    display: inline;
  }

  a {
    font-weight: 400;
  }
}

.ProjectsCredits--estimote {
  a {
    color: #63004A;
  }
}

.ProjectsCredits--hacktory {
  a {
    color: #6BBC6C;
  }
}

.ProjectsCredits--clockwork {
  a {
    color: #FF7200;
  }
}

.ProjectsCredits--hautec {
  a {
    color: #25367D;
  }
}



.ClockworkCredits {
  height: 600px;
  background-image: url(../images/clockwork-credits.jpg);
  background-size: cover;
  background-position: bottom right;
}

.ProjectsMeta-label {
  font-weight: $font-lt;
}
