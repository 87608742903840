.Showcase-inner {
  display: block;
  overflow: hidden;
  text-decoration: none;

  &:hover, &:focus {
    text-decoration: none;
  }

  @media (--lap-and-up) {
    height: 400px;
    &:hover {
      cursor: pointer;

      .ShowcaseDetails {
        visibility: hidden;
        opacity: 0;
        height: 0;
        width: 0;
      }

      .ShowcaseLink {
        visibility: visible;
        opacity: 1;
        height: auto;
        width: auto;

        span {
          text-decoration: none;
        }
      }
    }
  }

  @media (--desk) {
    &:hover {
      .ShowcaseImage {
        transform: scale(.82);
      }
    }
  }
}

.ShowcaseLink {
  visibility: hidden;
  opacity: 0;
  height: 0;
  width: 0;
}

.ShowcaseDetails, .ShowcaseLink {
  transition: visibility 0s, opacity 0.5s linear;
}

.Showcase--hacktory {
  background-color: $color-hacktory;
}

.ShowcaseRow {
  height: 100%;

  @media (--lap-and-up) {
    display: flex;
  }
}

.ShowcaseRow-inner {
  height: 100%;

  display: flex;
}

.ShowcaseColumn {
  height: 100%;
}

.ShowcaseColumn-item {
  height: 100%;
  display: flex;
  align-items: center;

  color: #fff;

  @media (--palm) {
    justify-content: center;
  }
}

.ShowcaseColumn-offsetRight {
  @media (--lap-and-up) {
    margin-right: -224px;
  }
}

.ShowcaseColumn-offsetLeft {
  @media (--lap-and-up) {
    margin-left: -112px;
  }
}


.ShowcaseImage {
  transition: all .3s ease;

  @media (--palm) {
    /*max-height: 400px;*/
    display: none;
  }
}

.Showcase {
  h1 {
    font-size: 56px;
    line-height: 58px;
    font-weight: $font-bl;

    @media (--palm) {
      font-size: 44px;
			line-height: 48px;
		}
  }

  h2 {
    font-size: 36px;
    line-height: 44px;
    font-weight: $font-re;

    @media (--palm) {
      font-size: 28px;
			line-height: 32px;
		}
  }

  p {
    font-size: 20px;
    line-height: 28px;
    font-weight: $font-lt;

    @media (--palm) {
			line-height: 26px;
		}
  }

  span {
    font-size: 18px;
    line-height: 22px;
    font-weight: $font-bd;

    text-transform: uppercase;

    letter-spacing: 2px;
  }

  @media (--palm) {
    .ShowcaseRow-inner:nth-of-type(odd) {
      flex-direction: column-reverse;
    }
  }
}
