/*------------------------------------
    #SPACING
------------------------------------*/

@media (min-width:$lap-start) and (max-width:$desk-start) {

  /**
   * Margin and padding helper classes. Use these to tweak layout on a micro
   * level.
   *
   * `.(m|p)(t|r|b|l|h|v)(-|+|0) {}` = margin/padding top/right/bottom/left/horizontal/vertical less/more/none
   */

  /* Predefine the variables below in order to alter and enable specific features. */
  $inuit-margin:                          24px; /* $base-spacing-unit !default; */
  $inuit-padding:                         24px; /* $base-spacing-unit !default; */



  /**
   * Margin helper classes.
   *
   * Add margins.
   */

  .u-md-m       { margin:           $inuit-margin !important; }
  .u-md-mt      { margin-top:       $inuit-margin !important; }
  .u-md-mr      { margin-right:     $inuit-margin !important; }
  .u-md-mb      { margin-bottom:    $inuit-margin !important; }
  .u-md-ml      { margin-left:      $inuit-margin !important; }
  .u-md-mh      { margin-right:     $inuit-margin !important; margin-left:      $inuit-margin !important; }
  .u-md-mv      { margin-top:       $inuit-margin !important; margin-bottom:    $inuit-margin !important; }


  /**
   * Add tiny margins.
   */

  .u-md-m--     { margin:           calc($inuit-margin / 4) !important; }
  .u-md-mt--    { margin-top:       calc($inuit-margin / 4) !important; }
  .u-md-mr--    { margin-right:     calc($inuit-margin / 4) !important; }
  .u-md-mb--    { margin-bottom:    calc($inuit-margin / 4) !important; }
  .u-md-ml--    { margin-left:      calc($inuit-margin / 4) !important; }
  .u-md-mh--    { margin-right:     calc($inuit-margin / 4) !important; margin-left:     calc($inuit-margin / 4) !important; }
  .u-md-mv--    { margin-top:       calc($inuit-margin / 4) !important; margin-bottom:   calc($inuit-margin / 4) !important; }


  /**
   * Add small margins.
   */

  .u-md-m-      { margin:           calc($inuit-margin / 2) !important; }
  .u-md-mt-     { margin-top:       calc($inuit-margin / 2) !important; }
  .u-md-mr-     { margin-right:     calc($inuit-margin / 2) !important; }
  .u-md-mb-     { margin-bottom:    calc($inuit-margin / 2) !important; }
  .u-md-ml-     { margin-left:      calc($inuit-margin / 2) !important; }
  .u-md-mh-     { margin-right:     calc($inuit-margin / 2) !important; margin-left:   calc($inuit-margin / 2) !important; }
  .u-md-mv-     { margin-top:       calc($inuit-margin / 2) !important; margin-bottom: calc($inuit-margin / 2) !important; }


  /**
   * Add large margins.
   */

  .u-md-m\+     { margin:           calc($inuit-margin * 2) !important; }
  .u-md-mt\+    { margin-top:       calc($inuit-margin * 2) !important; }
  .u-md-mr\+    { margin-right:     calc($inuit-margin * 2) !important; }
  .u-md-mb\+    { margin-bottom:    calc($inuit-margin * 2) !important; }
  .u-md-ml\+    { margin-left:      calc($inuit-margin * 2) !important; }
  .u-md-mh\+    { margin-right:     calc($inuit-margin * 2) !important; margin-left:      calc($inuit-margin * 2) !important; }
  .u-md-mv\+    { margin-top:       calc($inuit-margin * 2) !important; margin-bottom:    calc($inuit-margin * 2) !important; }


  /**
   * Add huge margins.
   */

  .u-md-m\+\+   { margin:           calc($inuit-margin * 4) !important; }
  .u-md-mt\+\+  { margin-top:       calc($inuit-margin * 4) !important; }
  .u-md-mr\+\+  { margin-right:     calc($inuit-margin * 4) !important; }
  .u-md-mb\+\+  { margin-bottom:    calc($inuit-margin * 4) !important; }
  .u-md-ml\+\+  { margin-left:      calc($inuit-margin * 4) !important; }
  .u-md-mh\+\+  { margin-right:     calc($inuit-margin * 4) !important; margin-left:   calc($inuit-margin * 4) !important; }
  .u-md-mv\+\+  { margin-top:       calc($inuit-margin * 4) !important; margin-bottom: calc($inuit-margin * 4) !important; }


  /**
   * Remove margins.
   */

  .u-md-m0      { margin:           0 !important; }
  .u-md-mt0     { margin-top:       0 !important; }
  .u-md-mr0     { margin-right:     0 !important; }
  .u-md-mb0     { margin-bottom:    0 !important; }
  .u-md-ml0     { margin-left:      0 !important; }
  .u-md-mh0     { margin-right:     0 !important; margin-left:      0 !important; }
  .u-md-mv0     { margin-top:       0 !important; margin-bottom:    0 !important; }


  /**
   * Negative margins.
   */

  .u-md--m      { margin:           -$inuit-margin !important; }
  .u-md--mt     { margin-top:       -$inuit-margin !important; }
  .u-md--mr     { margin-right:     -$inuit-margin !important; }
  .u-md--mb     { margin-bottom:    -$inuit-margin !important; }
  .u-md--ml     { margin-left:      -$inuit-margin !important; }
  .u-md--mh     { margin-right:     -$inuit-margin !important; margin-left:      -$inuit-margin !important; }
  .u-md--mv     { margin-top:       -$inuit-margin !important; margin-bottom:    -$inuit-margin !important; }


  /**
   * Tiny negative margins.
   */

  .u-md--m--    { margin:           calc(-$inuit-margin / 4) !important; }
  .u-md--mt--   { margin-top:       calc(-$inuit-margin / 4) !important; }
  .u-md--mr--   { margin-right:     calc(-$inuit-margin / 4) !important; }
  .u-md--mb--   { margin-bottom:    calc(-$inuit-margin / 4) !important; }
  .u-md--ml--   { margin-left:      calc(-$inuit-margin / 4) !important; }
  .u-md--mh--   { margin-right:     calc(-$inuit-margin / 4) !important; margin-left:     calc(-$inuit-margin / 4) !important; }
  .u-md--mv--   { margin-top:       calc(-$inuit-margin / 4) !important; margin-bottom:   calc(-$inuit-margin / 4) !important; }


  /**
   * Small negative margins.
   */

  .u-md--m-     { margin:           calc(-$inuit-margin / 2) !important; }
  .u-md--mt-    { margin-top:       calc(-$inuit-margin / 2) !important; }
  .u-md--mr-    { margin-right:     calc(-$inuit-margin / 2) !important; }
  .u-md--mb-    { margin-bottom:    calc(-$inuit-margin / 2) !important; }
  .u-md--ml-    { margin-left:      calc(-$inuit-margin / 2) !important; }
  .u-md--mh-    { margin-right:     calc(-$inuit-margin / 2) !important; margin-left:   calc(-$inuit-margin / 2) !important; }
  .u-md--mv-    { margin-top:       calc(-$inuit-margin / 2) !important; margin-bottom: calc(-$inuit-margin / 2) !important; }


  /**
   * Large negative margins.
   */

  .u-md--m\+    { margin:           calc(-$inuit-margin * 2) !important; }
  .u-md--mt\+   { margin-top:       calc(-$inuit-margin * 2) !important; }
  .u-md--mr\+   { margin-right:     calc(-$inuit-margin * 2) !important; }
  .u-md--mb\+   { margin-bottom:    calc(-$inuit-margin * 2) !important; }
  .u-md--ml\+   { margin-left:      calc(-$inuit-margin * 2) !important; }
  .u-md--mh\+   { margin-right:     calc(-$inuit-margin * 2) !important; margin-left:      calc(-$inuit-margin * 2) !important; }
  .u-md--mv\+   { margin-top:       calc(-$inuit-margin * 2) !important; margin-bottom:    calc(-$inuit-margin * 2) !important; }


  /**
   * Huge negative margins.
   */

  .u-md--m\+\+  { margin:           calc(-$inuit-margin * 4) !important; }
  .u-md--mt\+\+ { margin-top:       calc(-$inuit-margin * 4) !important; }
  .u-md--mr\+\+ { margin-right:     calc(-$inuit-margin * 4) !important; }
  .u-md--mb\+\+ { margin-bottom:    calc(-$inuit-margin * 4) !important; }
  .u-md--ml\+\+ { margin-left:      calc(-$inuit-margin * 4) !important; }
  .u-md--mh\+\+ { margin-right:     calc(-$inuit-margin * 4) !important; margin-left:   calc(-$inuit-margin * 4) !important; }
  .u-md--mv\+\+ { margin-top:       calc(-$inuit-margin * 4) !important; margin-bottom: calc(-$inuit-margin * 4) !important; }


  /**
   * Padding helper classes.
   *
   * Add paddings.
   */

  .u-md-p       { padding:          $inuit-padding !important; }
  .u-md-pt      { padding-top:      $inuit-padding !important; }
  .u-md-pr      { padding-right:    $inuit-padding !important; }
  .u-md-pb      { padding-bottom:   $inuit-padding !important; }
  .u-md-pl      { padding-left:     $inuit-padding !important; }
  .u-md-ph      { padding-right:    $inuit-padding !important; padding-left:    $inuit-padding !important; }
  .u-md-pv      { padding-top:      $inuit-padding !important; padding-bottom:  $inuit-padding !important; }


  /**
   * Add tiny paddings.
   */

  .u-md-p--     { padding:           calc($inuit-padding / 4) !important; }
  .u-md-pt--    { padding-top:       calc($inuit-padding / 4) !important; }
  .u-md-pr--    { padding-right:     calc($inuit-padding / 4) !important; }
  .u-md-pb--    { padding-bottom:    calc($inuit-padding / 4) !important; }
  .u-md-pl--    { padding-left:      calc($inuit-padding / 4) !important; }
  .u-md-ph--    { padding-right:     calc($inuit-padding / 4) !important; padding-left:      calc($inuit-padding / 4) !important; }
  .u-md-pv--    { padding-top:       calc($inuit-padding / 4) !important; padding-bottom:    calc($inuit-padding / 4) !important; }


  /**
   * Add small paddings.
   */

  .u-md-p-      { padding:           calc($inuit-padding / 2) !important; }
  .u-md-pt-     { padding-top:       calc($inuit-padding / 2) !important; }
  .u-md-pr-     { padding-right:     calc($inuit-padding / 2) !important; }
  .u-md-pb-     { padding-bottom:    calc($inuit-padding / 2) !important; }
  .u-md-pl-     { padding-left:      calc($inuit-padding / 2) !important; }
  .u-md-ph-     { padding-right:     calc($inuit-padding / 2) !important; padding-left:    calc($inuit-padding / 2) !important; }
  .u-md-pv-     { padding-top:       calc($inuit-padding / 2) !important; padding-bottom:  calc($inuit-padding / 2) !important; }


  /**
   * Add large paddings.
   */

  .u-md-p\+     { padding:           calc($inuit-padding * 2) !important; }
  .u-md-pt\+    { padding-top:       calc($inuit-padding * 2) !important; }
  .u-md-pr\+    { padding-right:     calc($inuit-padding * 2) !important; }
  .u-md-pb\+    { padding-bottom:    calc($inuit-padding * 2) !important; }
  .u-md-pl\+    { padding-left:      calc($inuit-padding * 2) !important; }
  .u-md-ph\+    { padding-right:     calc($inuit-padding * 2) !important; padding-left:   calc($inuit-padding * 2) !important; }
  .u-md-pv\+    { padding-top:       calc($inuit-padding * 2) !important; padding-bottom: calc($inuit-padding * 2) !important; }


  /**
   * Add huge paddings.
   */

  .u-md-p\+\+   { padding:           calc($inuit-padding * 4) !important; }
  .u-md-pt\+\+  { padding-top:       calc($inuit-padding * 4) !important; }
  .u-md-pr\+\+  { padding-right:     calc($inuit-padding * 4) !important; }
  .u-md-pb\+\+  { padding-bottom:    calc($inuit-padding * 4) !important; }
  .u-md-pl\+\+  { padding-left:      calc($inuit-padding * 4) !important; }
  .u-md-ph\+\+  { padding-right:     calc($inuit-padding * 4) !important; padding-left:    calc($inuit-padding * 4) !important; }
  .u-md-pv\+\+  { padding-top:       calc($inuit-padding * 4) !important; padding-bottom:  calc($inuit-padding * 4) !important; }


  /**
   * Remove paddings.
   */

  .u-md-p0      { padding:          0 !important; }
  .u-md-pt0     { padding-top:      0 !important; }
  .u-md-pr0     { padding-right:    0 !important; }
  .u-md-pb0     { padding-bottom:   0 !important; }
  .u-md-pl0     { padding-left:     0 !important; }
  .u-md-ph0     { padding-right:    0 !important; padding-left:     0 !important; }
  .u-md-pv0     { padding-top:      0 !important; padding-bottom:   0 !important; }

}
