nav {
	ul {
		display: flex;

		li {
			list-style: none;
		}
	}

	a {
		font-family: "Lato";
		font-weight: $font-re;
		font: 1rem;

		display: inline-block;
		margin: 10px;
		padding: 10px;
		color: #343232;
		text-decoration: none;
		border-radius: 3px;

		transition: all .2s ease;

		&:active,
		&:hover {
			transform: scale(1.2);
			text-decoration: none;
		}
	}
}


.SocialNavigation {
	display: flex;
	align-items: center;
  justify-content: flex-start;

	margin-left: -20px;
}
