.FAQ {
  a {
    color: $shop-color-secondary;
  }
}

.FAQ-toc {
  li {
    line-height: 20px;
    padding-bottom: 12px;
  }
}

.FAQ-content {
  ul, ol {
    font-size: 20px;
    line-height: 28px;
    font-weight: $font-lt;

    list-style: initial;
    padding-left: 20px;

    li {
      padding-bottom: 12px;
    }
  }

  ol {
    list-style-type: decimal;
  }

  i {
    font-style: italic;
  }

  h3 {
    font-size: 22px;
  }
}

.FAQ-warning {
  display: block;
  border: 1px solid $shop-color-primary;
  border-radius: 4px;
  padding: 18px 24px 20px;
  color: $shop-color-primary;
  font-weight: $font-re;
}

.FAQ-sectionDivider {
  margin-top: 96px;
  margin-bottom: 86px;
  display: block;
  border: 0;
  text-align: center;

  &:before {
    font-weight: $font-re;
    font-weight: 400;
    font-size: 36px;
    letter-spacing: 1.4rem;
    content: '...';
    color: $text-primary-color;
    position: relative;
    top: -30px;
  }
}
