.ThemesHero {
  background-color: #0b70e5
}

.ThemesHero h1, .ThemesHero h2 {
  color: #fff
}

.ThemesHero p {
  color: #fff;
  font-weight: 400
}

.ThemesHero a {
  color: #fff;
  cursor: pointer
}

.ThemesList-marketplaces li {
  -webkit-transition: all .2s ease;
  transition: all .2s ease
}

.ThemesList-marketplaces li:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2)
}

.ThemesItem {
  display: inline-block;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 8px 32px 0 rgba(58, 60, 61, .32);
  width: 100%
}

.ThemesItem-header {
  padding: 6px 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #f2f2f2
}

.ThemesItem-dot {
  height: 6px;
  width: 6px;
  border-radius: 50px;
  display: block;
  margin-right: 4px
}

.ThemesItem-dot--red {
  background-color: #ff6158
}

.ThemesItem-dot--yellow {
  background-color: #ffbd2e
}

.ThemesItem-dot--green {
  background-color: #28ce41
}

.ThemesItem-btn {
  display: inline-block;
  border: 2px solid #fff;
  border-radius: 50px;
  color: #fff;
  padding: 12px 48px 15px;
  font-size: 22px;
  cursor: pointer;
  -webkit-transition: ease all .2s;
  transition: ease all .2s
}

.ThemesItem-btn:hover {
  text-decoration: none;
  background-color: #fff;
  color: #0b70e5
}

.ThemesItem-btn--full {
  background-color: #0b70e5;
  color: #fff
}

.ThemesItem-content {
  position: relative;
  background-color: #f2f2f2
}

.ThemesItem-content img {
  width: 100%;
  display: block
}

.ThemesItem-contentDemo {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #0b70e5;
  -webkit-transition: ease all .4s;
  transition: ease all .4s;
  opacity: 0;
  visibility: hidden
}

.ThemesItem-content:hover .ThemesItem-contentDemo {
  opacity: 1;
  visibility: visible
}

.ThemesList-btn {
  display: inline-block;
  border: 2px solid #0b70e5;
  border-radius: 50px;
  color: #0b70e5;
  padding: 12px 48px 15px;
  font-size: 22px;
  cursor: pointer
}

.ThemesList-btn:hover {
  text-decoration: none
}

.ThemesList-btn--full {
  background-color: #0b70e5;
  color: #fff
}

.ThemesList-features {
  list-style: initial;
  list-style-position: inside;
  list-style-type: square
}

.ThemesList-features li {
  font-size: 20px;
  line-height: 40px
}

.ThemesList-marketplaces {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.ThemesList-marketplaces img {
  max-width: 120px
}

.ThemesContact {
  background-color: #0b70e5
}

.ThemesContact-heading, .ThemesContact a {
  color: #fff
}

.ThemesContact-btnWrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.ThemesContact-btn {
  display: inline-block;
  border: 2px solid #fff;
  border-radius: 50px;
  padding: 12px 48px 15px;
  font-size: 22px;
  -webkit-transition: ease all .2s;
  transition: ease all .2s
}

.ThemesContact-btn:hover {
  background-color: #fff;
  color: #0b70e5;
  text-decoration: none
}

.ThemesBtn {
  border: 2px solid #fff;
  border-radius: 50px;
  font-size: 22px;
  font-weight: 400;
  color: #fff;
  background: 0 0;
  cursor: pointer;
  padding: 12px 48px 15px;
  display: inline-block;
  position: relative;
  -webkit-transition: all .2s;
  transition: all .2s;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden
}

.ThemesBtn, .ThemesBtn:focus {
  outline: 0
}

.ThemesBtn:before {
  left: 0;
  top: -100%
}

.ThemesBtn:after {
  content: '';
  position: absolute;
  z-index: -1;
  -webkit-transition: all .2s;
  transition: all .2s
}

.ThemesBtn:hover {
  text-decoration: none
}

.ThemesBtn:hover:before {
  top: 0
}

.ThemesBtn:hover span {
  -webkit-transform: translateY(300%);
  transform: translateY(300%)
}

.ThemesBtn:active {
  background-color: #268aff;
  border-color: #268aff;
  top: 2px
}

.ThemesBtn:active:before {
  color: #053265
}

.ThemesBtn--full {
  background-color: #0b70e5;
  border-color: #0b70e5
}

.ThemesBtn:before {
  position: absolute;
  line-height: 2.5;
  font-size: 180%
}

.ThemesBtn:before, .ThemesBtn span {
  height: 100%;
  width: 100%;
  -webkit-transition: all .3s;
  transition: all .3s
}

.ThemesBtn span {
  display: inline-block;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden
}

.ThemesBtn-icon:before {
  content: '';
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: 50%;
  margin-top: -3px
}

.ThemesBtn-icon--browse:before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSIjZmZmIj48cGF0aCBkPSJNMjMgNS41VjIzSDUuNXYxSDI0VjUuNXptLTEtM2gtMVYyMUgyLjV2MUgyMnpNNy4wNyAxMC4yNDNjLjA4NC0uMTQuMjMtLjIzLjM5LS4yNC4xNjUtLjAxLjMyLjA1My40MjUuMTc3bDIuMDcgMi40ODYgMy42My01LjQ0M2MuMTAzLS4xNTUuMjg0LS4yNDIuNDcyLS4yMi4xODguMDIzLjM0Ni4xNDcuNDEyLjMyTDE3LjM0NSAxNUgyMFYwSDB2MTVoNC4yMTdsMi44NTQtNC43NTd6TTYgM2MxLjEwMy4wIDIgLjg5OCAyIDIgMCAxLjEwNC0uODk3IDItMiAycy0yLS44OTYtMi0yYzAtMS4xMDIuODk3LTIgMi0yem0xNCAxM0gwdjRoMjB6Ii8+PC9zdmc+);
  margin-top: -1px
}

.ThemesBtn-icon--preview:before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSIjZmZmIj48cGF0aCBkPSJNMjEuNSAzaC0xOUMxLjEyIDMgMCA0LjEyLjAgNS41VjhoMjRWNS41QzI0IDQuMTIgMjIuODc4IDMgMjEuNSAzek00IDdjLS41NTIuMC0xLS40NDgtMS0xcy40NDgtMSAxLTFjLjU1LjAgMSAuNDQ4IDEgMXMtLjQ1IDEtMSAxem0zIDBjLS41NTIuMC0xLS40NDgtMS0xcy40NDgtMSAxLTFjLjU1LjAgMSAuNDQ4IDEgMXMtLjQ1IDEtMSAxem0zIDBjLS41NTIuMC0xLS40NDgtMS0xcy40NDgtMSAxLTFjLjU1LjAgMSAuNDQ4IDEgMXMtLjQ1IDEtMSAxek0wIDE5LjVDMCAyMC44OCAxLjEyIDIyIDIuNSAyMmgxOWMxLjM3OC4wIDIuNS0xLjEyIDIuNS0yLjVWOUgwdjEwLjV6Ii8+PC9zdmc+)
}

.ThemesBtn-icon--contact:before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSIjZmZmIj48cGF0aCBkPSJNMjIuNSAzaC0yMUMuNjczIDMgMCAzLjY3My4wIDQuNXYxM2MwIC44MjcuNjczIDEuNSAxLjUgMS41SDN2My41YzAgLjIwMi4xMjIuMzg1LjMxLjQ2MkwzLjUgMjNjLjEzLjAuMjU4LS4wNS4zNTQtLjE0Nkw3LjcwNyAxOUgyMi41Yy44MjcuMCAxLjUtLjY3MyAxLjUtMS41di0xM2MwLS44MjctLjY3My0xLjUtMS41LTEuNXptLTE0IDljLS41NS4wLTEtLjQ0OC0xLTFzLjQ1LTEgMS0xIDEgLjQ0OCAxIDEtLjQ1IDEtMSAxem00IDBjLS41NS4wLTEtLjQ0OC0xLTFzLjQ1LTEgMS0xIDEgLjQ0OCAxIDEtLjQ1IDEtMSAxem00IDBjLS41NS4wLTEtLjQ0OC0xLTFzLjQ1LTEgMS0xIDEgLjQ0OCAxIDEtLjQ1IDEtMSAxeiIvPjwvc3ZnPg==);
  margin-top: -1px
}
