.InnerWrapper {
	max-width: 1180px;
	margin: 0 auto;
}

.SocialNavigation {
  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
      padding: 10px;
      list-style: none;
    }
  }

	a {
		transition: all .2s ease;
	}
}

.Section {
	h1 {
		font-size: 56px;
		line-height: 58px;
		font-weight: $font-bl;

		@media (--palm) {
			font-size: 44px;
			line-height: 48px;
		}
	}

	h2 {
		font-size: 36px;
		line-height: 44px;
		font-weight: $font-re;

		@media (--palm) {
			font-size: 28px;
			line-height: 32px;
		}
	}

	p {
		font-size: 20px;
		line-height: 28px;
		font-weight: $font-lt;

		@media (--palm) {
			line-height: 26px;
		}
	}
}




.SlickSlider {
	/*margin: 0 24px;*/
	overflow-x: hidden;
}


.slick-slider {
	width: 100%;
}

.slick-prev {
	@media (--lap-and-up) {
		left: 36%;
	}
}
.slick-next {
	@media (--lap-and-up) {
		right: 36%;
	}
}







.u-underline {
	text-decoration: underline;
}

.u-sectionMargin { margin-top: 240px; }
@media (min-width:320px) and (max-width:$lap-start) { .u-sm-sectionMargin { margin-top: 240px; } }
@media (min-width:$lap-start) and (max-width:$desk-start) { .u-md-sectionMargin { margin-top: 240px; } }
@media (min-width:$desk-start) { .u-lg-sectionMargin { margin-top: 240px;	} }


.u-sectionPadding { padding-top: 240px; }
@media (min-width:320px) and (max-width:$lap-start) { .u-sm-sectionPadding { padding-top: 240px; } }
@media (min-width:$lap-start) and (max-width:$desk-start) { .u-md-sectionPadding { padding-top: 240px; } }
@media (min-width:$desk-start) { .u-lg-sectionPadding { padding-top: 240px;	} }

.u-sectionMarginHalf { margin-top: 120px; }
@media (min-width:320px) and (max-width:$lap-start) { .u-sm-sectionMarginHalf { margin-top: 120px; } }
@media (min-width:$lap-start) and (max-width:$desk-start) { .u-md-sectionMarginHalf { margin-top: 120px; } }
@media (min-width:$desk-start) { .u-lg-sectionMarginHalf { margin-top: 120px;	} }


.u-sectionPaddingHalf { padding-top: 120px; }
@media (min-width:320px) and (max-width:$lap-start) { .u-sm-sectionPaddingHalf { padding-top: 120px; } }
@media (min-width:$lap-start) and (max-width:$desk-start) { .u-md-sectionPaddingHalf { padding-top: 120px; } }
@media (min-width:$desk-start) { .u-lg-sectionPaddingHalf { padding-top: 120px;	} }


.u-sectionMargin--bottom { margin-bottom: 240px; }
@media (min-width:320px) and (max-width:$lap-start) { .u-sm-sectionMargin--bottom { margin-bottom: 240px; } }
@media (min-width:$lap-start) and (max-width:$desk-start) { .u-md-sectionMargin--bottom { margin-bottom: 240px; } }
@media (min-width:$desk-start) { .u-lg-sectionMargin--bottom { margin-bottom: 240px;	} }


.u-sectionPadding--bottom { padding--bottom-bottom: 240px; }
@media (min-width:320px) and (max-width:$lap-start) { .u-sm-sectionPadding--bottom { padding-bottom: 240px; } }
@media (min-width:$lap-start) and (max-width:$desk-start) { .u-md-sectionPadding--bottom { padding-bottom: 240px; } }
@media (min-width:$desk-start) { .u-lg-sectionPadding--bottom { padding-bottom: 240px;	} }

.u-sectionMarginHalf--bottom { margin--bottom-bottom: 120px; }
@media (min-width:320px) and (max-width:$lap-start) { .u-sm-sectionMarginHalf--bottom { margin-bottom: 120px; } }
@media (min-width:$lap-start) and (max-width:$desk-start) { .u-md-sectionMarginHalf--bottom { margin-bottom: 120px; } }
@media (min-width:$desk-start) { .u-lg-sectionMarginHalf--bottom { margin-bottom: 120px;	} }


.u-sectionPaddingHalf--bottom { padding--bottom-bottom: 120px; }
@media (min-width:320px) and (max-width:$lap-start) { .u-sm-sectionPaddingHalf--bottom { padding-bottom: 120px; } }
@media (min-width:$lap-start) and (max-width:$desk-start) { .u-md-sectionPaddingHalf--bottom { padding-bottom: 120px; } }
@media (min-width:$desk-start) { .u-lg-sectionPaddingHalf--bottom { padding-bottom: 120px;	} }


.u-textBreakeOnMdLg {
	@media (--palm) {
		display: none;
	}
}


.example-appear {
  opacity: 0.01;
}

.example-appear.example-appear-active {
  opacity: 1;
  transition: opacity .3s linear;
}
