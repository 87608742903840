/*------------------------------------
    #SPACING
------------------------------------*/

@media (min-width:320px) and (max-width:$lap-start) {

  /**
   * Margin and padding helper classes. Use these to tweak layout on a micro
   * level.
   *
   * `.(m|p)(t|r|b|l|h|v)(-|+|0) {}` = margin/padding top/right/bottom/left/horizontal/vertical less/more/none
   */

  /* Predefine the variables below in order to alter and enable specific features. */
  $inuit-margin:                          24px; /* $base-spacing-unit !default; */
  $inuit-padding:                         24px; /* $base-spacing-unit !default; */



  /**
   * Margin helper classes.
   *
   * Add margins.
   */

  .u-sm-m       { margin:           $inuit-margin !important; }
  .u-sm-mt      { margin-top:       $inuit-margin !important; }
  .u-sm-mr      { margin-right:     $inuit-margin !important; }
  .u-sm-mb      { margin-bottom:    $inuit-margin !important; }
  .u-sm-ml      { margin-left:      $inuit-margin !important; }
  .u-sm-mh      { margin-right:     $inuit-margin !important; margin-left:      $inuit-margin !important; }
  .u-sm-mv      { margin-top:       $inuit-margin !important; margin-bottom:    $inuit-margin !important; }


  /**
   * Add tiny margins.
   */

  .u-sm-m--     { margin:           calc($inuit-margin / 4) !important; }
  .u-sm-mt--    { margin-top:       calc($inuit-margin / 4) !important; }
  .u-sm-mr--    { margin-right:     calc($inuit-margin / 4) !important; }
  .u-sm-mb--    { margin-bottom:    calc($inuit-margin / 4) !important; }
  .u-sm-ml--    { margin-left:      calc($inuit-margin / 4) !important; }
  .u-sm-mh--    { margin-right:     calc($inuit-margin / 4) !important; margin-left:     calc($inuit-margin / 4) !important; }
  .u-sm-mv--    { margin-top:       calc($inuit-margin / 4) !important; margin-bottom:   calc($inuit-margin / 4) !important; }


  /**
   * Add small margins.
   */

  .u-sm-m-      { margin:           calc($inuit-margin / 2) !important; }
  .u-sm-mt-     { margin-top:       calc($inuit-margin / 2) !important; }
  .u-sm-mr-     { margin-right:     calc($inuit-margin / 2) !important; }
  .u-sm-mb-     { margin-bottom:    calc($inuit-margin / 2) !important; }
  .u-sm-ml-     { margin-left:      calc($inuit-margin / 2) !important; }
  .u-sm-mh-     { margin-right:     calc($inuit-margin / 2) !important; margin-left:   calc($inuit-margin / 2) !important; }
  .u-sm-mv-     { margin-top:       calc($inuit-margin / 2) !important; margin-bottom: calc($inuit-margin / 2) !important; }


  /**
   * Add large margins.
   */

  .u-sm-m\+     { margin:           calc($inuit-margin * 2) !important; }
  .u-sm-mt\+    { margin-top:       calc($inuit-margin * 2) !important; }
  .u-sm-mr\+    { margin-right:     calc($inuit-margin * 2) !important; }
  .u-sm-mb\+    { margin-bottom:    calc($inuit-margin * 2) !important; }
  .u-sm-ml\+    { margin-left:      calc($inuit-margin * 2) !important; }
  .u-sm-mh\+    { margin-right:     calc($inuit-margin * 2) !important; margin-left:      calc($inuit-margin * 2) !important; }
  .u-sm-mv\+    { margin-top:       calc($inuit-margin * 2) !important; margin-bottom:    calc($inuit-margin * 2) !important; }


  /**
   * Add huge margins.
   */

  .u-sm-m\+\+   { margin:           calc($inuit-margin * 4) !important; }
  .u-sm-mt\+\+  { margin-top:       calc($inuit-margin * 4) !important; }
  .u-sm-mr\+\+  { margin-right:     calc($inuit-margin * 4) !important; }
  .u-sm-mb\+\+  { margin-bottom:    calc($inuit-margin * 4) !important; }
  .u-sm-ml\+\+  { margin-left:      calc($inuit-margin * 4) !important; }
  .u-sm-mh\+\+  { margin-right:     calc($inuit-margin * 4) !important; margin-left:   calc($inuit-margin * 4) !important; }
  .u-sm-mv\+\+  { margin-top:       calc($inuit-margin * 4) !important; margin-bottom: calc($inuit-margin * 4) !important; }


  /**
   * Remove margins.
   */

  .u-sm-m0      { margin:           0 !important; }
  .u-sm-mt0     { margin-top:       0 !important; }
  .u-sm-mr0     { margin-right:     0 !important; }
  .u-sm-mb0     { margin-bottom:    0 !important; }
  .u-sm-ml0     { margin-left:      0 !important; }
  .u-sm-mh0     { margin-right:     0 !important; margin-left:      0 !important; }
  .u-sm-mv0     { margin-top:       0 !important; margin-bottom:    0 !important; }


  /**
   * Negative margins.
   */

  .u-sm--m      { margin:           -$inuit-margin !important; }
  .u-sm--mt     { margin-top:       -$inuit-margin !important; }
  .u-sm--mr     { margin-right:     -$inuit-margin !important; }
  .u-sm--mb     { margin-bottom:    -$inuit-margin !important; }
  .u-sm--ml     { margin-left:      -$inuit-margin !important; }
  .u-sm--mh     { margin-right:     -$inuit-margin !important; margin-left:      -$inuit-margin !important; }
  .u-sm--mv     { margin-top:       -$inuit-margin !important; margin-bottom:    -$inuit-margin !important; }


  /**
   * Tiny negative margins.
   */

  .u-sm--m--    { margin:           calc(-$inuit-margin / 4) !important; }
  .u-sm--mt--   { margin-top:       calc(-$inuit-margin / 4) !important; }
  .u-sm--mr--   { margin-right:     calc(-$inuit-margin / 4) !important; }
  .u-sm--mb--   { margin-bottom:    calc(-$inuit-margin / 4) !important; }
  .u-sm--ml--   { margin-left:      calc(-$inuit-margin / 4) !important; }
  .u-sm--mh--   { margin-right:     calc(-$inuit-margin / 4) !important; margin-left:     calc(-$inuit-margin / 4) !important; }
  .u-sm--mv--   { margin-top:       calc(-$inuit-margin / 4) !important; margin-bottom:   calc(-$inuit-margin / 4) !important; }


  /**
   * Small negative margins.
   */

  .u-sm--m-     { margin:           calc(-$inuit-margin / 2) !important; }
  .u-sm--mt-    { margin-top:       calc(-$inuit-margin / 2) !important; }
  .u-sm--mr-    { margin-right:     calc(-$inuit-margin / 2) !important; }
  .u-sm--mb-    { margin-bottom:    calc(-$inuit-margin / 2) !important; }
  .u-sm--ml-    { margin-left:      calc(-$inuit-margin / 2) !important; }
  .u-sm--mh-    { margin-right:     calc(-$inuit-margin / 2) !important; margin-left:   calc(-$inuit-margin / 2) !important; }
  .u-sm--mv-    { margin-top:       calc(-$inuit-margin / 2) !important; margin-bottom: calc(-$inuit-margin / 2) !important; }


  /**
   * Large negative margins.
   */

  .u-sm--m\+    { margin:           calc(-$inuit-margin * 2) !important; }
  .u-sm--mt\+   { margin-top:       calc(-$inuit-margin * 2) !important; }
  .u-sm--mr\+   { margin-right:     calc(-$inuit-margin * 2) !important; }
  .u-sm--mb\+   { margin-bottom:    calc(-$inuit-margin * 2) !important; }
  .u-sm--ml\+   { margin-left:      calc(-$inuit-margin * 2) !important; }
  .u-sm--mh\+   { margin-right:     calc(-$inuit-margin * 2) !important; margin-left:      calc(-$inuit-margin * 2) !important; }
  .u-sm--mv\+   { margin-top:       calc(-$inuit-margin * 2) !important; margin-bottom:    calc(-$inuit-margin * 2) !important; }


  /**
   * Huge negative margins.
   */

  .u-sm--m\+\+  { margin:           calc(-$inuit-margin * 4) !important; }
  .u-sm--mt\+\+ { margin-top:       calc(-$inuit-margin * 4) !important; }
  .u-sm--mr\+\+ { margin-right:     calc(-$inuit-margin * 4) !important; }
  .u-sm--mb\+\+ { margin-bottom:    calc(-$inuit-margin * 4) !important; }
  .u-sm--ml\+\+ { margin-left:      calc(-$inuit-margin * 4) !important; }
  .u-sm--mh\+\+ { margin-right:     calc(-$inuit-margin * 4) !important; margin-left:   calc(-$inuit-margin * 4) !important; }
  .u-sm--mv\+\+ { margin-top:       calc(-$inuit-margin * 4) !important; margin-bottom: calc(-$inuit-margin * 4) !important; }


  /**
   * Padding helper classes.
   *
   * Add paddings.
   */

  .u-sm-p       { padding:          $inuit-padding !important; }
  .u-sm-pt      { padding-top:      $inuit-padding !important; }
  .u-sm-pr      { padding-right:    $inuit-padding !important; }
  .u-sm-pb      { padding-bottom:   $inuit-padding !important; }
  .u-sm-pl      { padding-left:     $inuit-padding !important; }
  .u-sm-ph      { padding-right:    $inuit-padding !important; padding-left:    $inuit-padding !important; }
  .u-sm-pv      { padding-top:      $inuit-padding !important; padding-bottom:  $inuit-padding !important; }


  /**
   * Add tiny paddings.
   */

  .u-sm-p--     { padding:           calc($inuit-padding / 4) !important; }
  .u-sm-pt--    { padding-top:       calc($inuit-padding / 4) !important; }
  .u-sm-pr--    { padding-right:     calc($inuit-padding / 4) !important; }
  .u-sm-pb--    { padding-bottom:    calc($inuit-padding / 4) !important; }
  .u-sm-pl--    { padding-left:      calc($inuit-padding / 4) !important; }
  .u-sm-ph--    { padding-right:     calc($inuit-padding / 4) !important; padding-left:      calc($inuit-padding / 4) !important; }
  .u-sm-pv--    { padding-top:       calc($inuit-padding / 4) !important; padding-bottom:    calc($inuit-padding / 4) !important; }


  /**
   * Add small paddings.
   */

  .u-sm-p-      { padding:           calc($inuit-padding / 2) !important; }
  .u-sm-pt-     { padding-top:       calc($inuit-padding / 2) !important; }
  .u-sm-pr-     { padding-right:     calc($inuit-padding / 2) !important; }
  .u-sm-pb-     { padding-bottom:    calc($inuit-padding / 2) !important; }
  .u-sm-pl-     { padding-left:      calc($inuit-padding / 2) !important; }
  .u-sm-ph-     { padding-right:     calc($inuit-padding / 2) !important; padding-left:    calc($inuit-padding / 2) !important; }
  .u-sm-pv-     { padding-top:       calc($inuit-padding / 2) !important; padding-bottom:  calc($inuit-padding / 2) !important; }


  /**
   * Add large paddings.
   */

  .u-sm-p\+     { padding:           calc($inuit-padding * 2) !important; }
  .u-sm-pt\+    { padding-top:       calc($inuit-padding * 2) !important; }
  .u-sm-pr\+    { padding-right:     calc($inuit-padding * 2) !important; }
  .u-sm-pb\+    { padding-bottom:    calc($inuit-padding * 2) !important; }
  .u-sm-pl\+    { padding-left:      calc($inuit-padding * 2) !important; }
  .u-sm-ph\+    { padding-right:     calc($inuit-padding * 2) !important; padding-left:   calc($inuit-padding * 2) !important; }
  .u-sm-pv\+    { padding-top:       calc($inuit-padding * 2) !important; padding-bottom: calc($inuit-padding * 2) !important; }


  /**
   * Add huge paddings.
   */

  .u-sm-p\+\+   { padding:           calc($inuit-padding * 4) !important; }
  .u-sm-pt\+\+  { padding-top:       calc($inuit-padding * 4) !important; }
  .u-sm-pr\+\+  { padding-right:     calc($inuit-padding * 4) !important; }
  .u-sm-pb\+\+  { padding-bottom:    calc($inuit-padding * 4) !important; }
  .u-sm-pl\+\+  { padding-left:      calc($inuit-padding * 4) !important; }
  .u-sm-ph\+\+  { padding-right:     calc($inuit-padding * 4) !important; padding-left:    calc($inuit-padding * 4) !important; }
  .u-sm-pv\+\+  { padding-top:       calc($inuit-padding * 4) !important; padding-bottom:  calc($inuit-padding * 4) !important; }


  /**
   * Remove paddings.
   */

  .u-sm-p0      { padding:          0 !important; }
  .u-sm-pt0     { padding-top:      0 !important; }
  .u-sm-pr0     { padding-right:    0 !important; }
  .u-sm-pb0     { padding-bottom:   0 !important; }
  .u-sm-pl0     { padding-left:     0 !important; }
  .u-sm-ph0     { padding-right:    0 !important; padding-left:     0 !important; }
  .u-sm-pv0     { padding-top:      0 !important; padding-bottom:   0 !important; }

}
