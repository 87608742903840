@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700';

$bookworm-font: 'Open Sans', sans-serif;

$bookworm-color-red: rgba(238,52,64,1);
$bookworm-color-yellow: rgba(243,201,0,1);
$bookworm-color-grey: rgba(238,238,238,1);

.Bookworm {
  background-color: $bookworm-color-grey;

  font-family: $bookworm-font;
}

.BookwormHeader {
  height: 742px;
  /*background-color: $bookworm-color-red;*/
  position: relative;
  z-index: 0;

  /*background-image: url(../images/bookworm-hero.jpg);*/

  clip-path: polygon(0 0, 100% 0, 100% 78%, 0% 100%);

  @media (--palm) {
    background: linear-gradient(rgba(238,52,64,.9), rgba(238,52,64,.9)),
                linear-gradient(rgba(70,80,130,1), rgba(70,80,130,1)),
                linear-gradient(rgba(238,52,64,1), rgba(238,52,64,1)),
                url(../images/bookworm-hero.jpg);

    background-blend-mode: normal, lighten, multiply, normal;
    background-position: 0;
  }
}

.BookwormHeaderHero {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  content: '';
  z-index: -1;

  @media (--lap-and-up) {
    background: linear-gradient(rgba(238,52,64,.9), rgba(238,52,64,.9)),
                linear-gradient(rgba(70,80,130,1), rgba(70,80,130,1)),
                linear-gradient(rgba(238,52,64,1), rgba(238,52,64,1)),
                url(../images/bookworm-hero.jpg);

    background-blend-mode: normal, lighten, multiply, normal;
    background-position: 0;
  }

  /*background-image: url(../images/bookworm-hero.jpg);*/

  /*transform: rotate(4deg) scale(1.1);*/
}

.BookwormLogo {
  text-align: center;

  @media (--lap-and-up) {
    margin-top: 90px;
  }

  svg {
    width: 100%;
  }
}

.BookwormSlogan {
  display: block;
  margin: 0 auto;

  font-weight: $font-lt;
  font-size: 20px;
  line-height: 30px;
  color: $neutral-color;

  @media (--lap-and-up) {
    width: 528px;
    font-size: 32px;
    line-height: 48px;
  }
}

.BookwormCta {
  text-align: center;
}

.BookwormBtn {
  height: 44px;
  min-width: 208px;

  padding-left: 32px;
  padding-right: 32px;

  border-radius: 2px;

  font-weight: $font-sb;

  transition: all .2s;

  span {
    position: relative;
    top: -1px;
  }
}

.BookwormBtn--ico {
  span {
    position: relative;
    top: -2px;
  }
}

.BookwormBtn--primary {
  background: $bookworm-color-yellow;
  border: 1px solid $bookworm-color-yellow;
  box-shadow: 0px 2px 6px 0px rgba(59,60,60,0.16);

  font-weight: $font-sb;
  color: $neutral-color;

  &:active {
    box-shadow: 0px 3px 10px 0px rgba(59,60,60,0.32);
  }

  &:hover {
    box-shadow: 0px 3px 10px 0px rgba(59,60,60,0.32);
  }
}

.BookwormBtn--contour {
  background: $bookworm-color-grey;
  border: 1px solid #C2C2C2;
  box-shadow: 0px 2px 6px 0px rgba(59,60,60,0.08);

  font-size: 16px;
  color: #A3A3A3;

  &:active {
    box-shadow: 0px 3px 10px 0px rgba(59,60,60,0.20);
  }

  &:hover {
    box-shadow: 0px 3px 10px 0px rgba(59,60,60,0.20);
  }
}

.BookwormBtn--contourPrimary {
  background: $neutral-color;
  border: 1px solid $bookworm-color-yellow;
  color: $bookworm-color-yellow;
}

.BookwormBtn--contourNeutral {
  background: $bookworm-color-grey;
  border: 1px solid #C2C2C2;
  color: #A3A3A3;
}

.BookwormBtnNote {
  font-weight: $font-lt;
  font-size: 12px;
  color: $neutral-color;
  line-height: 16px;
}

.BookwormBtnNote-link {
  font-style: italic;
}




.BookwormImgWrapper {
  height: 0;
  background-color: $bookworm-color-grey;

  @media (--palm) {
    margin-top: -240px;
  }



  @media (--desk) {
    height: 402px;
  }
}

.BookwormImg {
  display: block;
  margin: 0 auto;

  position: relative;
  z-index: 2;

  top: -70px;

  @media (--palm) {
    width: 100%;
    max-width: 400px;
  }

  @media (--lap) {
    max-width: 720px;
  }

  @media (--lap-and-up) {
    top: -260px;
  }
}

.BookwormLicenseWrapper {
  background-color: $neutral-color;
  clip-path: polygon(0 0, 100% 22%, 100% 100%, 0% 100%);

  display: flex;
  justify-content: center;

  @media (--lap-and-up) {
    /*height: 888px;*/
    height: 958px;
  }
}

.BookwormLicense {
  margin: 0 auto;
  padding-top: 180px;

  text-align: left;

  @media (--lap-and-up) {
    width: 718px;
  }
}

.BookwormLicense-subTitle {
  font-weight: $font-bd;
  font-size: 14px;
  color: #A3A3A3;
  letter-spacing: 1.2px;
  line-height: 38px;

  text-transform: uppercase;
}

.BookwormLicense-title {
  font-weight: $font-sb;
  color: #3B3C3D;

  font-size: 28px;
  line-height: 40px;

  @media (--lap-and-up) {
    font-size: 30px;
    line-height: 48px;
  }
}

.BookwormLicense-info {
  font-weight: $font-sb;
  color: #3B3C3D;
  font-size: 20px;
  line-height: 30px;

  @media (--lap-and-up) {
    font-size: 22px;
    line-height: 38px;
  }
}

.BookwormLicense-separator {
  height: 1px;
  width: 100%;
  display: block;
  background-color: #DCDCDC;
}

.BookwormLicense-list {
  font-weight: $font-lt;
  color: #3B3C3D;
  font-size: 18px;
  line-height: 26px;

  list-style-type: decimal;

  @media (--palm) {
    li {
      margin-bottom: 12px;
    }
  }

  @media (--lap-and-up) {
    font-size: 22px;
    line-height: 38px;
  }
}

.BookwormLicense-listLink {
  color: $bookworm-color-yellow;
}

.BookwormLicense-cta {
  background-color: $neutral-color;
  text-align: center;

  @media (--lap-and-up) {
    box-shadow: 0 0 0 1px #E6E6E6,
    0 4px 12px 0 rgba(0,0,0,.08),
    0 2px 2px 0 rgba(0,0,0,.06);

    border-radius: 2px;
    margin: 78px auto 0;
    padding: 48px 24px;
  }

  > span {
    @media (--palm) {
      display: block;
    }
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }
}

.BookwormLicense-btn {
  margin: 0 auto;

  @media (--palm) {
    display: block;
  }

  svg {
    fill: $bookworm-color-yellow;
  }
}

.BookwormLicense-btnIco--github {
  position: relative;
  top: 2px;
}

.BookwormLicense-divider {
  @media (--palm) {
    display: block;
  }
}




.BookwormFooter {
  display: flex;
  justify-content: center;
}

.BookwormFooter-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (--lap-and-up) {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

.BookwormFooter-donation {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--lap-and-up) {
    flex-direction: row;
    align-items: flex-end;
  }

  @media (--lap) {
    max-width: 718px;
  }

  @media (--desk) {
    width: 718px;
    margin: 0 auto;
  }
}

.BookwormFooter-donationInfo {
  display: flex;
  align-items: flex-end;
}

.BookwormFooter-donationShake {
  height: 73px;
  /*width: 39px;
  float: left;*/
}

.BookwormFooter-donationTitle {
  font-weight: $font-lt;
  color: #A3A3A3;
  font-size: 18px;
  line-height: 26px;

  @media (--lap-and-up) {
    font-size: 20px;
    line-height: 28px;

    margin-top: 18px;
  }
}

.BookwormFooter-btn {
  min-width: 236px;
  display: block;

  margin: 0 auto;

  @media (--lap-and-up) {
    margin-bottom: 5px;
  }
}

.BookwormFooter-copyright {
  font-weight: $font-lt;
  font-size: 14px;
  color: #A3A3A3;

  text-align: center;

  @media (--lap-and-up) {
    float: right;
  }
}
