/*------------------------------------
    #SPACING
------------------------------------*/


/**
 * Margin and padding helper classes. Use these to tweak layout on a micro
 * level.
 *
 * `.(m|p)(t|r|b|l|h|v)(-|+|0) {}` = margin/padding top/right/bottom/left/horizontal/vertical less/more/none
 */

/* Predefine the variables below in order to alter and enable specific features. */
$inuit-margin:                          24px; /* $base-spacing-unit !default; */
$inuit-padding:                         24px; /* $base-spacing-unit !default; */



/**
 * Margin helper classes.
 *
 * Add margins.
 */

.u-m       { margin:           $inuit-margin !important; }
.u-mt      { margin-top:       $inuit-margin !important; }
.u-mr      { margin-right:     $inuit-margin !important; }
.u-mb      { margin-bottom:    $inuit-margin !important; }
.u-ml      { margin-left:      $inuit-margin !important; }
.u-mh      { margin-right:     $inuit-margin !important; margin-left:      $inuit-margin !important; }
.u-mv      { margin-top:       $inuit-margin !important; margin-bottom:    $inuit-margin !important; }


/**
 * Add tiny margins.
 */

.u-m--     { margin:           calc($inuit-margin / 4) !important; }
.u-mt--    { margin-top:       calc($inuit-margin / 4) !important; }
.u-mr--    { margin-right:     calc($inuit-margin / 4) !important; }
.u-mb--    { margin-bottom:    calc($inuit-margin / 4) !important; }
.u-ml--    { margin-left:      calc($inuit-margin / 4) !important; }
.u-mh--    { margin-right:     calc($inuit-margin / 4) !important; margin-left:     calc($inuit-margin / 4) !important; }
.u-mv--    { margin-top:       calc($inuit-margin / 4) !important; margin-bottom:   calc($inuit-margin / 4) !important; }


/**
 * Add small margins.
 */

.u-m-      { margin:           calc($inuit-margin / 2) !important; }
.u-mt-     { margin-top:       calc($inuit-margin / 2) !important; }
.u-mr-     { margin-right:     calc($inuit-margin / 2) !important; }
.u-mb-     { margin-bottom:    calc($inuit-margin / 2) !important; }
.u-ml-     { margin-left:      calc($inuit-margin / 2) !important; }
.u-mh-     { margin-right:     calc($inuit-margin / 2) !important; margin-left:   calc($inuit-margin / 2) !important; }
.u-mv-     { margin-top:       calc($inuit-margin / 2) !important; margin-bottom: calc($inuit-margin / 2) !important; }


/**
 * Add large margins.
 */

.u-m\+     { margin:           calc($inuit-margin * 2) !important; }
.u-mt\+    { margin-top:       calc($inuit-margin * 2) !important; }
.u-mr\+    { margin-right:     calc($inuit-margin * 2) !important; }
.u-mb\+    { margin-bottom:    calc($inuit-margin * 2) !important; }
.u-ml\+    { margin-left:      calc($inuit-margin * 2) !important; }
.u-mh\+    { margin-right:     calc($inuit-margin * 2) !important; margin-left:      calc($inuit-margin * 2) !important; }
.u-mv\+    { margin-top:       calc($inuit-margin * 2) !important; margin-bottom:    calc($inuit-margin * 2) !important; }


/**
 * Add huge margins.
 */

.u-m\+\+   { margin:           calc($inuit-margin * 4) !important; }
.u-mt\+\+  { margin-top:       calc($inuit-margin * 4) !important; }
.u-mr\+\+  { margin-right:     calc($inuit-margin * 4) !important; }
.u-mb\+\+  { margin-bottom:    calc($inuit-margin * 4) !important; }
.u-ml\+\+  { margin-left:      calc($inuit-margin * 4) !important; }
.u-mh\+\+  { margin-right:     calc($inuit-margin * 4) !important; margin-left:   calc($inuit-margin * 4) !important; }
.u-mv\+\+  { margin-top:       calc($inuit-margin * 4) !important; margin-bottom: calc($inuit-margin * 4) !important; }


/**
 * Remove margins.
 */

.u-m0      { margin:           0 !important; }
.u-mt0     { margin-top:       0 !important; }
.u-mr0     { margin-right:     0 !important; }
.u-mb0     { margin-bottom:    0 !important; }
.u-ml0     { margin-left:      0 !important; }
.u-mh0     { margin-right:     0 !important; margin-left:      0 !important; }
.u-mv0     { margin-top:       0 !important; margin-bottom:    0 !important; }


/**
 * Negative margins.
 */

.u--m      { margin:           -$inuit-margin !important; }
.u--mt     { margin-top:       -$inuit-margin !important; }
.u--mr     { margin-right:     -$inuit-margin !important; }
.u--mb     { margin-bottom:    -$inuit-margin !important; }
.u--ml     { margin-left:      -$inuit-margin !important; }
.u--mh     { margin-right:     -$inuit-margin !important; margin-left:      -$inuit-margin !important; }
.u--mv     { margin-top:       -$inuit-margin !important; margin-bottom:    -$inuit-margin !important; }


/**
 * Tiny negative margins.
 */

.u--m--    { margin:           calc(-$inuit-margin / 4) !important; }
.u--mt--   { margin-top:       calc(-$inuit-margin / 4) !important; }
.u--mr--   { margin-right:     calc(-$inuit-margin / 4) !important; }
.u--mb--   { margin-bottom:    calc(-$inuit-margin / 4) !important; }
.u--ml--   { margin-left:      calc(-$inuit-margin / 4) !important; }
.u--mh--   { margin-right:     calc(-$inuit-margin / 4) !important; margin-left:     calc(-$inuit-margin / 4) !important; }
.u--mv--   { margin-top:       calc(-$inuit-margin / 4) !important; margin-bottom:   calc(-$inuit-margin / 4) !important; }


/**
 * Small negative margins.
 */

.u--m-     { margin:           calc(-$inuit-margin / 2) !important; }
.u--mt-    { margin-top:       calc(-$inuit-margin / 2) !important; }
.u--mr-    { margin-right:     calc(-$inuit-margin / 2) !important; }
.u--mb-    { margin-bottom:    calc(-$inuit-margin / 2) !important; }
.u--ml-    { margin-left:      calc(-$inuit-margin / 2) !important; }
.u--mh-    { margin-right:     calc(-$inuit-margin / 2) !important; margin-left:   calc(-$inuit-margin / 2) !important; }
.u--mv-    { margin-top:       calc(-$inuit-margin / 2) !important; margin-bottom: calc(-$inuit-margin / 2) !important; }


/**
 * Large negative margins.
 */

.u--m\+    { margin:           calc(-$inuit-margin * 2) !important; }
.u--mt\+   { margin-top:       calc(-$inuit-margin * 2) !important; }
.u--mr\+   { margin-right:     calc(-$inuit-margin * 2) !important; }
.u--mb\+   { margin-bottom:    calc(-$inuit-margin * 2) !important; }
.u--ml\+   { margin-left:      calc(-$inuit-margin * 2) !important; }
.u--mh\+   { margin-right:     calc(-$inuit-margin * 2) !important; margin-left:      calc(-$inuit-margin * 2) !important; }
.u--mv\+   { margin-top:       calc(-$inuit-margin * 2) !important; margin-bottom:    calc(-$inuit-margin * 2) !important; }


/**
 * Huge negative margins.
 */

.u--m\+\+  { margin:           calc(-$inuit-margin * 4) !important; }
.u--mt\+\+ { margin-top:       calc(-$inuit-margin * 4) !important; }
.u--mr\+\+ { margin-right:     calc(-$inuit-margin * 4) !important; }
.u--mb\+\+ { margin-bottom:    calc(-$inuit-margin * 4) !important; }
.u--ml\+\+ { margin-left:      calc(-$inuit-margin * 4) !important; }
.u--mh\+\+ { margin-right:     calc(-$inuit-margin * 4) !important; margin-left:   calc(-$inuit-margin * 4) !important; }
.u--mv\+\+ { margin-top:       calc(-$inuit-margin * 4) !important; margin-bottom: calc(-$inuit-margin * 4) !important; }


/**
 * Padding helper classes.
 *
 * Add paddings.
 */

.u-p       { padding:          $inuit-padding !important; }
.u-pt      { padding-top:      $inuit-padding !important; }
.u-pr      { padding-right:    $inuit-padding !important; }
.u-pb      { padding-bottom:   $inuit-padding !important; }
.u-pl      { padding-left:     $inuit-padding !important; }
.u-ph      { padding-right:    $inuit-padding !important; padding-left:    $inuit-padding !important; }
.u-pv      { padding-top:      $inuit-padding !important; padding-bottom:  $inuit-padding !important; }


/**
 * Add tiny paddings.
 */

.u-p--     { padding:           calc($inuit-padding / 4) !important; }
.u-pt--    { padding-top:       calc($inuit-padding / 4) !important; }
.u-pr--    { padding-right:     calc($inuit-padding / 4) !important; }
.u-pb--    { padding-bottom:    calc($inuit-padding / 4) !important; }
.u-pl--    { padding-left:      calc($inuit-padding / 4) !important; }
.u-ph--    { padding-right:     calc($inuit-padding / 4) !important; padding-left:      calc($inuit-padding / 4) !important; }
.u-pv--    { padding-top:       calc($inuit-padding / 4) !important; padding-bottom:    calc($inuit-padding / 4) !important; }


/**
 * Add small paddings.
 */

.u-p-      { padding:           calc($inuit-padding / 2) !important; }
.u-pt-     { padding-top:       calc($inuit-padding / 2) !important; }
.u-pr-     { padding-right:     calc($inuit-padding / 2) !important; }
.u-pb-     { padding-bottom:    calc($inuit-padding / 2) !important; }
.u-pl-     { padding-left:      calc($inuit-padding / 2) !important; }
.u-ph-     { padding-right:     calc($inuit-padding / 2) !important; padding-left:    calc($inuit-padding / 2) !important; }
.u-pv-     { padding-top:       calc($inuit-padding / 2) !important; padding-bottom:  calc($inuit-padding / 2) !important; }


/**
 * Add large paddings.
 */

.u-p\+     { padding:           calc($inuit-padding * 2) !important; }
.u-pt\+    { padding-top:       calc($inuit-padding * 2) !important; }
.u-pr\+    { padding-right:     calc($inuit-padding * 2) !important; }
.u-pb\+    { padding-bottom:    calc($inuit-padding * 2) !important; }
.u-pl\+    { padding-left:      calc($inuit-padding * 2) !important; }
.u-ph\+    { padding-right:     calc($inuit-padding * 2) !important; padding-left:   calc($inuit-padding * 2) !important; }
.u-pv\+    { padding-top:       calc($inuit-padding * 2) !important; padding-bottom: calc($inuit-padding * 2) !important; }


/**
 * Add huge paddings.
 */

.u-p\+\+   { padding:           calc($inuit-padding * 4) !important; }
.u-pt\+\+  { padding-top:       calc($inuit-padding * 4) !important; }
.u-pr\+\+  { padding-right:     calc($inuit-padding * 4) !important; }
.u-pb\+\+  { padding-bottom:    calc($inuit-padding * 4) !important; }
.u-pl\+\+  { padding-left:      calc($inuit-padding * 4) !important; }
.u-ph\+\+  { padding-right:     calc($inuit-padding * 4) !important; padding-left:    calc($inuit-padding * 4) !important; }
.u-pv\+\+  { padding-top:       calc($inuit-padding * 4) !important; padding-bottom:  calc($inuit-padding * 4) !important; }


/**
 * Remove paddings.
 */

.u-p0      { padding:          0 !important; }
.u-pt0     { padding-top:      0 !important; }
.u-pr0     { padding-right:    0 !important; }
.u-pb0     { padding-bottom:   0 !important; }
.u-pl0     { padding-left:     0 !important; }
.u-ph0     { padding-right:    0 !important; padding-left:     0 !important; }
.u-pv0     { padding-top:      0 !important; padding-bottom:   0 !important; }
