/*------------------------------------
    #DEFAULTS
------------------------------------*/

/* High-level base settings */
$base-font-size:          16px;
$base-line-height:        24px;
$base-font-size-large:    18px;
$base-line-height-large:  27px;
$base-text-color:         #333;
$base-background-color:   #fff;

$base-spacing-unit: $base-line-height;


/* Headings */
$heading-size-1:          32px; /* .alpha */
$heading-size-2:          28px; /* .beta */
$heading-size-3:          24px; /* .gamma */
$heading-size-4:          20px; /* .delta */
$heading-size-5:          18px; /* .epsilon */
$heading-size-6:          16px; /* .zeta */

$ultra-size:        96px;
$giga-size:         72px;
$mega-size:         56px;
$kilo-size:         36px;

$milli-size:        12px;
$micro-size:        11px;


/* Border radius */
$border-radius-big: 8px;
$border-radius:     4px;


/*------------------------------------
    #BREAKPOINTS
------------------------------------*/

$lap-start:          640px;
$desk-start:         960px;
$desk-wide-start:    1441px;

$breakpoints: (
  "palm"          "screen and (max-width: 639px)",
  "lap"           "screen and (min-width: 640px) and (max-width: 959px)",
  "lap-and-up"    "screen and (min-width: 640px)",
  "portable"      "screen and (max-width: 959px)",
  "desk"          "screen and (min-width: 960px)",
  "desk-wide"     "screen and (min-width: 1441px)",
  "retina"        "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)"
);

@custom-media --palm (max-width: 639px);
@custom-media --lap (min-width: 640px) and (max-width: 959px);
@custom-media --lap-and-up (min-width: 640px);
@custom-media --portable (max-width: 959px);
@custom-media --desk (min-width: 960px);
@custom-media --desk-wide (min-width: 1441px);
@custom-media --retina (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi);


/*------------------------------------
    #COLORS
------------------------------------*/

/* Default */
$default-color:       #000000;
$neutral-color:       #FFFFFF;

/* Main */
$primary-color:				rgba(34,56,125,1);
$primary-80-color:    rgba(34,56,125,.8);
$primary-60-color:    rgba(34,56,125,.6);
$primary-40-color:    rgba(34,56,125,.4);
$primary-20-color:    rgba(34,56,125,.2);


/* Background */
$background-primary-color:  	#FFFFFF;
$background-secondary-color: 	#FAFAFA;
$background-tertiary-color:		rgba(37,54,125,1);
$background-footer-color:			#323334;

/* Text */
$text-primary-color:    #212121;
$text-secondary-color:  #B6B6B6;

$default-100-color:		rgba(0,0,0,1);
$default-80-color:		rgba(0,0,0,.8);
$default-60-color:		rgba(0,0,0,.6);
$default-40-color:		rgba(0,0,0,.4);
$default-20-color:		rgba(0,0,0,.2);

$neutral-100-color:		rgba(255,255,255,1);
$neutral-80-color:		rgba(255,255,255,.8);
$neutral-60-color:		rgba(255,255,255,.6);
$neutral-40-color:		rgba(255,255,255,.4);
$neutral-20-color:		rgba(255,255,255,.2);


/* Shop colors */
$shop-color-primary: #FF715B;
$shop-color-secondary: #20b6a2;
$shop-color-secondary: #0B70E5;
$shop-color-grey: #4C5454;


/*------------------------------------
    #FONTS
------------------------------------*/

/* Fonts */
/*
@font-face {font-family: 'LatoLatin-Black';src: url('../fonts/LatoLatin-Black.eot');src: url('../fonts/LatoLatin-Black.eot?#iefix') format('embedded-opentype'),url('../fonts/LatoLatin-Black.woff') format('woff'),url('../fonts/LatoLatin-Black.woff2') format('woff2'),url('../fonts/LatoLatin-Black.ttf') format('truetype');}
@font-face {font-family: 'LatoLatin-Bold';src: url('../fonts/LatoLatin-Bold.eot');src: url('../fonts/LatoLatin-Bold.eot?#iefix') format('embedded-opentype'),url('../fonts/LatoLatin-Bold.woff') format('woff'),url('../fonts/LatoLatin-Bold.woff2') format('woff2'),url('../fonts/LatoLatin-Bold.ttf') format('truetype');}
@font-face {font-family: 'LatoLatin-Regular';src: url('../fonts/LatoLatin-Regular.eot');src: url('../fonts/LatoLatin-Regular.eot?#iefix') format('embedded-opentype'),url('../fonts/LatoLatin-Regular.woff') format('woff'),url('../fonts/LatoLatin-Regular.woff2') format('woff2'),url('../fonts/LatoLatin-Regular.ttf') format('truetype');}
@font-face {font-family: 'LatoLatin-Light';src: url('../fonts/LatoLatin-Light.eot');src: url('../fonts/LatoLatin-Light.eot?#iefix') format('embedded-opentype'),url('../fonts/LatoLatin-Light.woff') format('woff'),url('../fonts/LatoLatin-Light.woff2') format('woff2'),url('../fonts/LatoLatin-Light.ttf') format('truetype');}
*/

@font-face {
   font-family: "Lato";
   font-path: '../fonts/LatoLatin-Light';
   font-style: normal;
   font-weight: 300;
}

@font-face {
   font-family: "Lato";
   font-path: '../fonts/LatoLatin-Regular';
   font-style: normal;
   font-weight: 400;
}

@font-face {
   font-family: "Lato";
   font-path: '../fonts/LatoLatin-Bold';
   font-style: normal;
   font-weight: 700;
}

@font-face {
   font-family: "Lato";
   font-path: '../fonts/LatoLatin-Black';
   font-style: normal;
   font-weight: 900;
}



$font: 'Lato', sans-serif;
$font-lt: 300;
$font-re: 400;
$font-sb: 600;
$font-bd: 700;
$font-bl: 900;





/* COLORS */
$color-hacktory: #6BBC6C;
