/*------------------------------------
    #BASE
------------------------------------*/

/**
 * A thin layer on top of normalize.css that provides a starting point more
 * suitable for web applications. Removes the default spacing and border for
 * appropriate elements.
 */

 /**
 * High-, page-level styling.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project,
 *    sourced from our default variables. The `font-size` is calculated to exist
 *    in ems, the `line-height` is calculated to exist unitlessly.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */

html, body {
  height: 100%;

  transition: opacity 400ms;

  font-family: $font;
  font-weight: $font-lt;
}

html {
  /* font-family: $font-1; */
  font-size: ($base-font-size / 16px) * 1em; /* [1] */
  line-height: $base-line-height / $base-font-size; /* [1] */
  /* font-weight: $font-1-lt; */
  background-color: $background-primary-color;
  color: $text-primary-color;
  overflow-y: scroll; /* [2] */
  min-height: 100%; /* [3] */

  /*  Font rendering related */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*  optical non metrical */
  text-rendering: optimizeLegibility;

  /*  smooth scrolling on iOS devices */
  -webkit-overflow-scrolling: touch;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover,
a:focus,
a:active {
  text-decoration: underline;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
figure,
p,
pre {
  margin: 0;
}

button {
  background: transparent;
  border: 0;
  padding: 0;
}

/**
 * Work around a Firefox/IE bug where the transparent `button` background
 * results in a loss of the default `button` focus styles.
 */

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

iframe {
  border: 0;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

small {
  font-size: inherit;
}

/**
 * Suppress the focus outline on links that cannot be accessed via keyboard.
 * This prevents an unwanted focus outline from appearing around elements that
 * might still respond to pointer events.
 */

[tabindex="-1"]:focus {
  outline: none !important;
}


/*------------------------------------
    #HEADINGS
------------------------------------*/

h1, h2, h3, h4, h5, h6 {
  /* font-family: $font-2;
  font-weight: $font-2-re; */
}

/* Base spacing */
h1, h2, h3, h4, h5, h6, hgroup,
ul, ol, dl,
blockquote, p, address,
table, figure,
pre {
  margin-bottom: 24px;
}

/**
 * Headings 1–6, with corresponding Greek-alphabet abstract classes for double-
 * stranded heading hierarchy: csswizardry.com/2012/02/pragmatic-practical-font-sizing-in-css
 */

h1,
.u-alpha,
%u-alpha {
  @mixin font-size $heading-size-1;
}

h2,
.u-beta,
%u-beta {
  @mixin font-size $heading-size-3;
}

h3,
.u-gamma,
%u-gamma {
  @mixin font-size $heading-size-3;
}

h4,
.u-delta,
%u-delta {
  @mixin font-size $heading-size-4;
}

h5,
.u-epsilon,
%u-epsilon {
  @mixin font-size $heading-size-5;
}

h6,
.u-zeta,
%u-zeta {
  @mixin font-size $heading-size-6;
}

.u-milli,
%u-milli {
  @mixin font-size $milli-size;
}

.u-kilo,
%u-kilo {
  @mixin font-size kilo-size;
}

.u-mega,
%u-mega {
  @mixin font-size mega-size;
}

.u-giga,
%u-giga {
  @mixin font-size $giga-size;
}

.u-ultra,
%u-ultra {
  @mixin font-size $ultra-size;
}

/*------------------------------------
    #PARAGRAPH
------------------------------------*/



/*------------------------------------
    #MISC
------------------------------------*/

/* Box model */
*, *:before, *:after {
  -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
}

input[type="search"] {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}
